import { hUseApi, HUseApiProps } from '@hybrain/mui.hooks.use-api';
import PatientRegModel from 'types/patient-reg.model';
import patientStore from 'stores/patient-store';
import { parseJSON } from 'utils/helpers/json';
import CryptoJS from 'crypto-js';
import { formatDateTime } from 'utils/helpers/date';
import api from 'api';

const getPatientReg = <T>(props: UseApiPatientRegProps<T>) => {
	if (props.withPatientReg === false) return undefined;
	if ((!props.method || props.method === 'GET' || props.method === 'get') && props.withPatientReg !== true) return undefined;

	const patientReg: PatientRegModel | null = parseJSON(sessionStorage.getItem('patientReg'));

	var key = CryptoJS.enc.Utf8.parse('C*F-JaNdRgUkXp2s');
	var iv = CryptoJS.enc.Utf8.parse('&F)H@McQfTjWnZr4');

	const options = {
		iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	};

	const payload = {
		PatientRegID: patientReg?.PatientRegID,
		PatientID: patientReg?.PatientID,
		MainCaseID: patientReg?.MainCaseID,
		PatientRegTypeID: patientReg?.PatientRegTypeID,
		PatientStatusLevel: patientReg?.PatientStatusLevel,
		AttendingPhysician: patientReg?.AttendingPhysician,
		ChiefComplaint: patientReg?.ChiefComplaint,
		PatientRoomID: patientReg?.PatientRoomID,
		StationID: patientReg?.StationID,
		StationName: patientReg?.StationName,
		RoomTypeID: patientReg?.RoomTypeID,
		RoomTypeName: patientReg?.RoomTypeName,
		PatientStatusLevelName: patientReg?.PatientStatusLevelName,
		AdmissionDateTime: formatDateTime(
			patientReg?.patientreg_type_current?.AdmissionDate,
			patientReg?.patientreg_type_current?.AdmissionTime
		),
		PatientCompleteName: patientReg?.patient?.CompleteName,
		AgeYMD: patientReg?.AgeOption?.AgeYMD,
		Age: patientReg?.AgeOption?.Age,
		HospitalID: patientReg?.patient?.HospitalID,
		LastName: patientReg?.patient?.LastName,
		FirstName: patientReg?.patient?.FirstName,
		MiddleName: patientReg?.patient?.MiddleName,
		Suffix: patientReg?.patient?.Suffix,
		Gender: patientReg?.patient?.Gender,
		Birthdate: patientReg?.patient?.Birthdate,
		CompleteAddress: patientReg?.patient?.CompleteAddress,
		RegisterType: patientReg?.patientreg_type_current?.RegisterType ?? '',
		station: patientReg?.station ?? {},
	};

	const encrypted = CryptoJS.AES.encrypt(JSON.stringify(payload) ?? '', key, options).toString();

	return encrypted;
};

type UseApiPatientRegProps<T> = {
	withPatientReg?: boolean;
} & HUseApiProps<T, typeof patientStore.patientReg.PatientRegID>;

const useApiPatientReg = <T extends any = any>({ ...props }: UseApiPatientRegProps<T>, deps?: React.DependencyList) =>
	hUseApi<T, typeof patientStore.patientReg.PatientRegID>(
		{
			...props,
			api,
			params: {
				PatientRegID: parseJSON(sessionStorage.getItem('patientReg'))?.PatientRegID,
				PatientReg: getPatientReg(props),
				...props.params,
			},
		},
		deps
	);
export default useApiPatientReg;
