import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const StockIssuancePage = lazy(() => import('modules/mms/pages/StockIssuance/StockIssuancePage'));
// const StockIssuanceFromStockRequestPage = lazy(
// 	() => import('modules/mms/pages/StockIssuance/pages/StockIssuanceFromStockRequest/StockIssuanceFromStockRequestPage')
// );
// const StockIssuanceFromDeliveryPage = lazy(
// 	() => import('modules/mms/pages/StockIssuance/pages/StockIssuanceFromDelivery/StockIssuanceFromDeliveryPage')
// );
// const StockIssuanceFromConsignmentPage = lazy(
// 	() => import('modules/mms/pages/StockIssuance/pages/StockIssuanceFromConsignment/StockIssuanceFromConsignmentPage')
// );
const StockIssuancePendingTransactionPage = lazy(
	() => import('modules/mms/pages/StockIssuance/pages/StockIssuancePendingTransaction/StockIssuancePendingTransactionPage')
);
const StockIssuancePostedTransactionPage = lazy(
	() => import('modules/mms/pages/StockIssuance/pages/StockIssuancePostedTransaction/StockIssuancePostedTransactionPage')
);
const StockIssuanceVoidedTransactionPage = lazy(
	() => import('modules/mms/pages/StockIssuance/pages/StockIssuanceVoidedTransaction/StockIssuanceVoidedTransactionPage')
);

const route: RouteObject = {
	path: 'stock-issuance',
	element: <StockIssuancePage />,
	children: [
		// { index: true, element: <StockIssuanceFromStockRequestPage /> },
		// { path: 'from-delivery', element: <StockIssuanceFromDeliveryPage /> },
		// { path: 'from-consignment', element: <StockIssuanceFromConsignmentPage /> },
		{ index: true, element: <StockIssuancePendingTransactionPage /> },
		{ path: 'posted-transaction', element: <StockIssuancePostedTransactionPage /> },
		{ path: 'voided-transaction', element: <StockIssuanceVoidedTransactionPage /> },
	],
};

export default route;
