import PostProcessingModel from 'modules/his/pages/PatientPostProcessing/types/post-processing.model';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const PatientPostProcessingPage = lazy(() => import('modules/his/pages/PatientPostProcessing/PatientPostProcessingPage'));
const PatientPostProcessingMainPage = lazy(() => import('modules/his/pages/PatientPostProcessing/pages/PatientPostProcessingMainPage'));

const postProcessingModelModelProps: PostProcessingModel = {
	module: 'Post Processing',
	path: 'post-processing',
};

const route: RouteObject[] = [
	{
		element: <PatientPostProcessingPage {...postProcessingModelModelProps} />,
		children: [
			{
				index: true,
				element: <PatientPostProcessingMainPage {...postProcessingModelModelProps} />,
			},
		],
	},
];

export default route;
