import React from 'react';
import Grid, { GridProps, GridSize } from '@mui/material/Grid';
import FormSelect, { FormSelectProps } from '../FormSelect';

interface GridFormSelectProps {
	gridProps?: GridProps;
	xl?: boolean | GridSize | undefined;
	lg?: boolean | GridSize | undefined;
	md?: boolean | GridSize | undefined;
	xs?: boolean | GridSize | undefined;
}

const GridFormSelect = <T extends any>({ className, gridProps, xl, lg, md, xs, ...props }: GridFormSelectProps & FormSelectProps<T>) => {
	return (
		<Grid item xs={xs ?? 12} md={md} lg={lg} xl={xl} className={className} {...gridProps}>
			<FormSelect {...props} />
		</Grid>
	);
};

export default GridFormSelect;
