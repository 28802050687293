import React from 'react';
import BaseDialog from 'components/feedback/BaseDialog';
import BaseForm from 'components/form/BaseForm';
import Grid from '@mui/material/Grid';
import { HFormProps } from '@hybrain/mui.ui.final-form.h-form';
import { HDialogProps } from '@hybrain/mui.ui.dialogs.h-dialog';

type BaseFormDialogProps = {
	open: boolean;
	onClose: () => void;
	title?: string;
	spacing?: number;
	dialogProps?: HDialogProps;
	maxWidth?: HDialogProps['maxWidth'];
} & HFormProps;

const BaseFormDialog = ({ title, open, onClose, spacing, dialogProps, children, maxWidth, ...props }: BaseFormDialogProps) => {
	return (
		<BaseDialog title={title || 'asfawef'} open={open} onClose={onClose} maxWidth={maxWidth} {...dialogProps}>
			<BaseForm {...props}>
				{(form) =>
					children instanceof Function ? (
						<Grid container spacing={spacing || 1}>
							{children(form)}
						</Grid>
					) : (
						<Grid container spacing={spacing || 1}>
							{children}
						</Grid>
					)
				}
			</BaseForm>
		</BaseDialog>
	);
};

export default BaseFormDialog;
