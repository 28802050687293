import { makeAutoObservable } from 'mobx';

type ConfirmationDialogStorePayload = {
	onAgree: (done: () => void) => void;
	onCancel: () => void;
};

class ConfirmationDialogStore {
	open = false;
	onAgree: (done: () => void) => void = () => {};
	loading = false;
	onCancel: () => void = () => {};

	constructor() {
		makeAutoObservable(this);
	}

	onClose() {
		this.open = false;
	}

	start(payload: ConfirmationDialogStorePayload) {
		this.open = true;
		Object.keys(payload).forEach((property) => {
			(this as any)[property] = (payload as any)[property];
		});
	}

	setLoading(loading: boolean) {
		this.loading = loading;
	}
}

export default new ConfirmationDialogStore();
