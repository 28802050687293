import BaseDialog from 'components/feedback/BaseDialog';
import ConfirmationDialog from 'components/feedback/ConfirmationDialog';
import BaseCheckbox from 'components/inputs/BaseCheckbox';
import BaseDatePicker from 'components/inputs/BaseDatePicker';
import BaseTextField from 'components/inputs/BaseTextField';
import BaseButton from 'components/inputs/buttons/BaseButton';
import useApi from 'hooks/useApi';
import { useState } from 'react';
import authStore from 'stores/auth-store';
import Swal from 'sweetalert2';
import ItemCategoryModel from 'types/item-category.model';
import StockCardMain from '../../types/StockCardMain';

interface ItemStockAdjustmentDialogProps {
	open: boolean;
	onClose: () => void;
	selectedItem: StockCardMain;
	item_category: ItemCategoryModel;
	sdID: number;
	refresh: () => void;
}

const ItemStockAdjustmentDialog = ({ open, onClose, selectedItem, item_category, sdID, refresh }: ItemStockAdjustmentDialogProps) => {
	const [isEnable, setIsEnable] = useState<boolean>(false);
	const [qty, setQty] = useState<number>(1);
	const [remarks, setRemarks] = useState<string>('');
	const [itemExpiry, setItemExpiry] = useState<Date>(new Date());
	const [openConf, setOpenConf] = useState<boolean>(false);
	const [saveData, setSaveData] = useState<{}>();

	const { callApi: stockAdjustmentApi } = useApi<{}>({
		url: 'mms/items/inventory/stock-adjustment-insert',
		method: 'post',
		login: true,
		onSuccess() {
			refresh();
			onClose();
		},
		immediate: false,
	});

	const save = (isDeduct: boolean) => {
		if (qty > 0) {
			let data = {
				SILineID: selectedItem.SILineID,
				ItemSubDeptID: sdID,
				ItemID: selectedItem.ItemID,
				LotNumber: selectedItem.LotNo,
				Barcode: selectedItem.Barcode,
				Unit: selectedItem.BasedUnit,
				Remarks: remarks,
				Qty: isDeduct == true ? qty * -1 : qty,
				isDeduct: isDeduct,
				isActive: true,
				ItemExpiry: itemExpiry,
				SmallUnitPrice: selectedItem.BasedPrice,
				isInventory: selectedItem.isInventory,
				isConsigned: selectedItem.isConsigned,
				SupplierID: selectedItem.SupplierID,
				isDispensing: false, // daw wai mn gna query sa PMC sa main stock card
				hasEntry: false, // false if no entries
				JournalID: 0, // default 0 lng anay ky wai pa entries
			};

			setSaveData({
				adjustment: data,
				item_category: item_category,
				EncodedBy: authStore?.user?.CompleteName ?? 'Stock Adjustment',
			});

			setOpenConf(true);
		} else
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Invalid quantity',
				showConfirmButton: false,
				timer: 2500,
			});
	};

	return (
		<>
			<ConfirmationDialog open={openConf} onClose={() => setOpenConf(false)} onAgree={() => stockAdjustmentApi(saveData)} />
			<BaseDialog title="Item Stock Adjustment" open={open} onClose={onClose} maxWidth="sm">
				<div className="flex flex-grow flex-col flex-wrap gap-3">
					<BaseTextField label="Lot Number" value={selectedItem.LotNo} readOnly />
					<BaseTextField label="Barcode" value={selectedItem.Barcode} readOnly />
					<BaseTextField label="Item Description" value={selectedItem.Description} readOnly />
					<div className="flex flex-nowrap gap-3">
						<BaseTextField label="Remaining Qty" value={selectedItem.RemainingQty ?? 0} readOnly />
						<BaseTextField label="Unit" value={selectedItem.BasedUnit} readOnly />
						<BaseTextField label="Unit Price" value={selectedItem.BasedPrice} readOnly />
					</div>
					<BaseTextField label="Quantity" value={qty} onChange={(e) => setQty(Number(e.target.value))} />
					<BaseTextField label="Remarks" value={remarks} multiline minRows={3} onChange={(e) => setRemarks(e.target.value)} />

					<div className="flex flex-grow flex-nowrap gap-3">
						<BaseDatePicker label="Item Expiry" value={itemExpiry} onChange={(e) => setItemExpiry(e)} disabled={!isEnable} />
						<BaseCheckbox label="Enable" checked={isEnable} onChange={() => (isEnable == true ? setIsEnable(false) : setIsEnable(true))} />
					</div>

					<div className="flex flex-grow flex-col grid grid-cols-2 gap-3">
						<BaseButton>ADD - CREDITED TO COGS</BaseButton>
						<BaseButton backgroundColor={'danger'}>SUBTRACT - DEBIT TO COGS</BaseButton>

						<BaseButton>ADD - CREDIT TO INVENTORY</BaseButton>
						<BaseButton backgroundColor={'danger'}>SUBTRACT - DEBIT TO INVENTORY</BaseButton>

						<BaseButton onClick={() => save(false)}>ADD WITHOUT ACCOUNTING ENTRY</BaseButton>
						<BaseButton onClick={() => save(true)} backgroundColor={'danger'}>
							SUBTRACT WITHOUT ACCOUNTING ENTRY
						</BaseButton>
					</div>
				</div>
			</BaseDialog>
		</>
	);
};

export default ItemStockAdjustmentDialog;
