import Dashboard from 'modules/mms/pages/InventoryItems/components/Dashboard';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import ViewBeginningBalancePage from 'modules/mms/pages/InventoryItems/components/BeginningBalance/ViewBeginningBalancePage';
import StockCardPage from 'modules/mms/pages/InventoryItems/components/StockCard/StockCardPage';

const InventoryItems = lazy(() => import('modules/mms/pages/InventoryItems/InventoryItems'));
const InventoryItemsPage = lazy(() => import('modules/mms/pages/InventoryItems/InventoryItemsPage'));
const InventoryItemsDashboard = lazy(() => import('modules/mms/pages/InventoryItems/InventoryItemsDashboard'));

const route: RouteObject = {
	path: 'inventory-items',
	element: <InventoryItems />,
	children: [
		{ index: true, element: <InventoryItemsPage /> },
		{
			path: 'inventory-items-dashboard',
			element: <InventoryItemsDashboard />,
			children: [
				{ index: true, element: <Dashboard /> },
				{ path: 'inventory-items-beginning-balance', element: <ViewBeginningBalancePage /> },
				{ path: 'inventory-items-stock-card', element: <StockCardPage /> },
			],
		},
	],
};

export default route;
