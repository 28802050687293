import React from 'react';
import { HTable, HTableProps } from '@hybrain/mui.ui.data-display.h-table';
import useWindowDimension from 'hooks/useWindowDimension';
import LayoutStore from 'stores/layout-store';
import { observer } from 'mobx-react-lite';

type BaseTableProps<T> = {
	tableRowAlignment?:
		| 'baseline'
		| 'length'
		| 'sub'
		| 'super'
		| 'top'
		| 'text-top'
		| 'middle'
		| 'bottom'
		| 'text-bottom'
		| 'initial'
		| 'inherit';
	tableColumnPadding?: string | number;
} & HTableProps<T>;

const getMobileGrid = (windowWidth: number) => {
	if (windowWidth < 800) {
		return { sm: 6 };
	}

	if (windowWidth < 900 && LayoutStore.sideBarState) {
		return { sm: 12 };
	}

	return undefined;
};

function BaseTable<T extends any = any>({ actionColumnOptions, ...props }: BaseTableProps<T>) {
	const [windowWidth] = useWindowDimension();

	return (
		<HTable
			headerAlign="center"
			tableHeaderBackgroundColor={(theme) => theme.palette.primary.dark}
			withHeaderBorder
			actionColumnOptions={{
				position: 'left',
				fit: true,
				...actionColumnOptions,
			}}
			maxHeight={false}
			mobileGrid={getMobileGrid(windowWidth)}
			dateTimeFormat="timezone"
			{...props}
		/>
	);
}

export default observer(BaseTable);
