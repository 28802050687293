import suppliersModel from 'modules/his/pages/Suppliers/types/suppliers.model';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const SuppliersPage = lazy(() => import('modules/his/pages/Suppliers/SuppliersPage'));
const SuppliersMainPage = lazy(() => import('modules/his/pages/Suppliers/pages/SuppliersMainPage/SuppliersMainPage'));

const suppliersProps: suppliersModel = {
	module: 'Suppliers',
	path: 'suppliers',
};

const route: RouteObject[] = [
	{
		element: <SuppliersPage {...suppliersProps} />,
		children: [
			{
				index: true,
				element: <SuppliersMainPage {...suppliersProps} />,
			},
		],
	},
];

export default route;
