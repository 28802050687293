import modulePathJournalsModel from 'modules/ams/pages/ModuleJournals/types/module-path-journals.model';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const ModuleJournalsPage = lazy(() => import('modules/ams/pages/ModuleJournals/ModuleJournals'));
const ModuleJournalsMainPage = lazy(() => import('modules/ams/pages/ModuleJournals/pages/ModuleJournalsMainPage'));

const  modulePathJournalsProps: modulePathJournalsModel = {
	module: 'Journals',
	path: 'module/journals',
};

const route: RouteObject[] = [
	{
		element: <ModuleJournalsPage {...modulePathJournalsProps} />,
		children: [
			{
				index: true,
				element: <ModuleJournalsMainPage {...modulePathJournalsProps} />,
			},
		],
	},
];

export default route;
