import { Grid } from '@mui/material';
import BaseFormDialog from 'components/feedback/BaseFormDialog';
import GridFormRadioGroup from 'components/form/grid/GridFormRadioGroup';
import GridFormTextField from 'components/form/grid/GridFormTextField';
import AddButton from 'components/inputs/buttons/AddButton';
import { useState } from 'react';
import AccountingTemplateDialog from './AccountingTemplateDialog';
import AccountTemplateModel from 'types/account-template.model';
import GridFormSelect from 'components/form/grid/GridFormSelect';
import SubDepartmentModel from 'types/sub-department.model';
import useApi from 'hooks/useApi';
import BaseButton from 'components/inputs/buttons/BaseButton';
import ConfirmationDialog from 'components/feedback/ConfirmationDialog';
import usePatientStore from 'hooks/usePatientStore';
import authStore from 'stores/auth-store';

interface AddPatientAdjustmentDialogProps {
	refresh: () => void;
}

const AddPatientAdjustmentDialog = ({ refresh }: AddPatientAdjustmentDialogProps) => {
	const [openDialog, setOpenDialog] = useState(false);
	const [openConfDialog, setOpenConfDialog] = useState(false);
	const [openAccTemplateDialog, setOpenAccTemplateDialog] = useState(false);
	const [selectedAccTemplate, setSelectedAccTemplate] = useState<AccountTemplateModel>();

	const [adjustmentType, setAdjustmentType] = useState('Credit');
	const [subDeptID, setSubDeptID] = useState<any>(0);
	const [description, setDescription] = useState('');
	const [amount, setAmount] = useState('');

	const [saveData, setSaveData] = useState({});

	const {
		patientReg: { PatientRegID },
	} = usePatientStore();

	const { data: subDepts, loading: sdLoading } = useApi<SubDepartmentModel[]>({
		url: 'his/maintenance/sub-departments/active',
		onSuccess({ data }) {
			const SubDeptData = data?.response?.[0]; // sa c# Audiometry(35) na d ang default if DEBUG
			setSubDeptID(SubDeptData?.SubDeptID);
		},
	});

	const { callApi: addAdjustmentApi } = useApi<{}>({
		url: 'his/patient-registry/adjustment/',
		method: 'post',
		login: true,
		onSuccess() {
			refresh();
			closeDialog();
		},
	});

	const handleSubmit = (data: any) => {
		setSaveData({
			PatientRegID: PatientRegID,
			SubDeptID: data.SubDeptID,
			Description: data.Description,
			AdjustmentType: data.AdjustmentType,
			Amount: data.AdjustmentType === 'Debit' ? data.Amount : -data.Amount,
			TemplateID: selectedAccTemplate?.AccountTemplateID,
			CreatedBy: authStore.user?.CompleteName ?? 'From Patient Adjustment',
		});

		setOpenConfDialog(true);
	};

	const closeDialog = () => {
		setSelectedAccTemplate(undefined);
		setSubDeptID(1);
		setDescription('');
		setAmount('');
		setAdjustmentType('Credit');
		setOpenDialog(false);
	};

	return (
		<div>
			<AddButton onClick={() => setOpenDialog(true)}>Add</AddButton>
			<AccountingTemplateDialog
				open={openAccTemplateDialog}
				onClose={() => setOpenAccTemplateDialog(false)}
				setTemplate={setSelectedAccTemplate}
			/>
			<ConfirmationDialog
				open={openConfDialog}
				onAgree={() => {
					// console.log(saveData);
					addAdjustmentApi(saveData);
				}}
				onClose={() => setOpenConfDialog(false)}
			>
				Are you sure you want to add adjustment?
			</ConfirmationDialog>
			<BaseFormDialog
				className="m-7"
				title="Add Patient Account Adjustment"
				open={openDialog}
				onClose={() => closeDialog()}
				onSubmit={handleSubmit}
				maxWidth="sm"
				initialValues={{
					AdjustmentType: adjustmentType,
					AccountingTemplate: selectedAccTemplate?.Name,
					SubDeptID: subDeptID ?? 1,
					Description: description,
					Amount: amount,
				}}
				keepDirtyOnReinitialize
			>
				<Grid container spacing={2}>
					<GridFormRadioGroup
						name="AdjustmentType"
						label="Adjustment Type"
						defaultValue={'Credit'}
						options={['Credit', 'Debit']}
						onChange={(e) => setAdjustmentType((e.target as HTMLInputElement).value)}
						xs={12}
						row
						required
					/>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<GridFormTextField
								name="AccountingTemplate"
								label="Accounting Template"
								onDoubleClick={() => setOpenAccTemplateDialog(true)}
								xs={10}
								helperText="Double click to select template"
								autoComplete="off"
								required
							/>
							<Grid item xs={2}>
								<BaseButton onClick={() => setSelectedAccTemplate(undefined)} disabled={selectedAccTemplate ? false : true}>
									Clear
								</BaseButton>
							</Grid>
						</Grid>
					</Grid>
					<GridFormSelect
						name="SubDeptID"
						label="Sub Department"
						options={subDepts}
						loading={sdLoading}
						optionText="Name"
						optionValue="SubDeptID"
						value={subDeptID}
						onChange={(e) => setSubDeptID(e.target.value)}
						xs={12}
						required
					/>
					<GridFormTextField
						name="Description"
						label="Description"
						onChange={(e) => setDescription(e.target.value)}
						multiline
						minRows={3}
						xs={12}
						required
					/>
					<GridFormTextField name="Amount" label="Amount" onChange={(e) => setAmount(e.target.value)} type="number" xs={12} required />
				</Grid>
			</BaseFormDialog>
		</div>
	);
};

export default AddPatientAdjustmentDialog;
