import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import ViewPatientModel from 'modules/his/pages/ViewPatient/types/view-patient.model';
const AncillaryServicesPage = lazy(() => import('modules/his/pages/AncillaryServices/AncillaryServicesPage'));
const PatientDetailsHeaderPage = lazy(() => import('modules/his/pages/ViewPatient/PatientDetailsHeaderPage'));
const RequisitionsPage = lazy(() => import('modules/his/pages/ViewPatient/pages/Requistions/RequisitionsPage'));
const NewRequisitionPage = lazy(() => import('modules/his/pages/ViewPatient/pages/NewRequisition/NewRequisitionPage'));
const PostChargesPage = lazy(() => import('modules/his/pages/ViewPatient/pages/PostCharges/PostChargesPage'));
const ViewRequisitionPage = lazy(() => import('modules/his/pages/ViewPatient/pages/Requistions/components/ViewRequisitionPage'));
const EditRequisitionPage = lazy(() => import('modules/his/pages/ViewPatient/pages/Requistions/components/EditRequisitionPage'));

const ancillaryProps: ViewPatientModel = {
	module: 'Ancillary',
	path: 'ancillary-services',
};

const route: RouteObject[] = [
	{
		index: true,
		element: <AncillaryServicesPage />,
	},
	{
		path: 'patient/view',
		element: <PatientDetailsHeaderPage {...ancillaryProps} />,
		children: [
			{
				path: 'requisition-list',
				element: <RequisitionsPage {...ancillaryProps} />,
			},
			{
				path: 'requisition-view',
				element: <ViewRequisitionPage {...ancillaryProps} />,
			},
			{
				path: 'requisition-edit',
				element: <EditRequisitionPage {...ancillaryProps} />,
			},
			{
				path: 'new-requisition',
				element: <NewRequisitionPage {...ancillaryProps} />,
			},
			{
				path: 'post-charges',
				element: <PostChargesPage {...ancillaryProps} />,
			},
		],
	},
];

export default route;
