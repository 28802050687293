import BaseTable from 'components/data-display/BaseTable';
import BaseDialog from 'components/feedback/BaseDialog';
import SendIcon from '@mui/icons-material/Send';
import BaseButton from 'components/inputs/buttons/BaseButton';
import useApi from 'hooks/useApi';
import { useEffect, useState } from 'react';
import SearchTextField from 'components/inputs/text-fields/SearchTextField';
import ConfirmationDialog from 'components/feedback/ConfirmationDialog';
import AccountsModel from 'modules/his/pages/ItemsAndServices/types/accounts-model';

interface AccountsDialogProps {
	open: boolean;
	onClose: () => void;
	setAccount: (data: AccountsModel) => void;
}

const AccountsDialog = ({ open, onClose, setAccount }: AccountsDialogProps) => {
	const [searchWord, setSearchWord] = useState('');
	const [openConfDialog, setOpenConfDialog] = useState(false);
	const [data, setData] = useState<AccountsModel>({} as AccountsModel);

	const {
		callApi,
		data: accounts,
		loading: dashboardLoading,
	} = useApi<AccountsModel[]>({
		url: 'ams/accounts/accounts-by-type',
		params: {
			Type: 3,
		},
		immediate: false,
	});

	const filteredAccounts = searchWord
		? accounts?.reduce((acc: AccountsModel[], item) => {
				if (
					item?.Name.toLowerCase().includes(searchWord.toLowerCase()) ||
					item?.Description.toLowerCase().includes(searchWord.toLowerCase())
				) {
					acc.push(item);
				}
				return acc;
		  }, [])
		: accounts;

	const selectAccount = (data: AccountsModel) => {
		setData(data);
		setOpenConfDialog(true);
	};

	useEffect(() => {
		if (open == true) callApi();
	}, [open]);

	return (
		<BaseDialog title="Accounting Templates" open={open} onClose={onClose} maxWidth="md">
			<ConfirmationDialog
				open={openConfDialog}
				onAgree={() => {
					setAccount(data);
					onClose();
				}}
				onClose={() => setOpenConfDialog(false)}
			>
				Are you sure you want to select this account?
			</ConfirmationDialog>
			<SearchTextField value={searchWord} onChange={(e) => setSearchWord(e.target.value)} />
			<BaseTable
				className="mt-7"
				items={filteredAccounts}
				loading={dashboardLoading}
				headers={[
					{ label: 'Code', value: 'Code' },
					{ label: 'Name', value: 'Name' },
					{ label: 'Description', value: 'Description' },
				]}
				actionColumn={(data) => (
					<div className="flex">
						<BaseButton icon onClick={() => selectAccount(data)}>
							<SendIcon />
						</BaseButton>
					</div>
				)}
			/>
		</BaseDialog>
	);
};

export default AccountsDialog;
