import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const PurchaseOrderPage = lazy(() => import('modules/mms/pages/PurchaseOrder/PurchaseOrderPage'));
const PurchaseOrderPendingRequestsPage = lazy(
	() => import('modules/mms/pages/PurchaseOrder/pages/PurchaseOrderPendingRequests/PurchaseOrderPendingRequestsPage')
);
const PurchaseOrderPendingPOPage = lazy(
	() => import('modules/mms/pages/PurchaseOrder/pages/PurchaseOrderPendingPO/PurchaseOrderPendingPOPage')
);
const PurchaseOrderPostedPOPage = lazy(
	() => import('modules/mms/pages/PurchaseOrder/pages/PurchaseOrderPostedPO/PurchaseOrderPostedPOPage')
);
const PurchaseOrderVoidedPOPage = lazy(
	() => import('modules/mms/pages/PurchaseOrder/pages/PurchaseOrderVoidedPO/PurchaseOrderVoidedPOPage')
);

const route: RouteObject = {
	path: 'purchase-order',
	element: <PurchaseOrderPage />,
	children: [
		{ index: true, element: <PurchaseOrderPendingRequestsPage /> },
		{ path: 'pending', element: <PurchaseOrderPendingPOPage /> },
		{ path: 'posted', element: <PurchaseOrderPostedPOPage /> },
		{ path: 'voided', element: <PurchaseOrderVoidedPOPage /> },
	],
};

export default route;
