import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Homepage = lazy(() => import('modules/auth/pages/Homepage/Homepage'));

const route: RouteObject = {
	path: '/',
	element: <Homepage />,
	// children: [
	// 	{
	// 		path: 'login',
	// 		element: <LoginPage />,
	// 	},
	// ],
};

export default route;
