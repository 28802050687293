import BaseTable from 'components/data-display/BaseTable';
import BaseDialog from 'components/feedback/BaseDialog';
import BaseTextField from 'components/inputs/BaseTextField';
import AddButton from 'components/inputs/buttons/AddButton';
import DeleteIconButton from 'components/inputs/buttons/icons/DeleteIconButton';
import useApi from 'hooks/useApi';
import { useEffect, useState } from 'react';
import ItemCategoryModel from 'types/item-category.model';
import UnitDosingModel from 'types/MMS/unit-dosing.model';
import { formatDateTime } from 'utils/helpers/date';
import StockCardMain from '../../types/StockCardMain';
import UnitDosingAddItemDialog from './UnitDosingAddItemDialog';

interface UnitDosingDialogProps {
	open: boolean;
	onClose: () => void;
	selectedItem: StockCardMain;
	item_category: ItemCategoryModel;
}

const UnitDosingDialog = ({ open, onClose, selectedItem, item_category }: UnitDosingDialogProps) => {
	const [openDialog, setOpenDialog] = useState<boolean>(false);

	const { callApi, data, loading } = useApi<UnitDosingModel[]>({
		url: 'mms/items/inventory/unit-dosing/view-items',
		params: {
			SILineID: selectedItem.SILineID,
		},
		immediate: false,
	});

	useEffect(() => {
		if (open == true) callApi();
	}, [open]);

	return (
		<>
			<UnitDosingAddItemDialog
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				item={selectedItem}
				main_item_category={item_category}
				refresh={callApi}
			/>
			<BaseDialog title="Unit Dosing" open={open} onClose={onClose} maxWidth="xl">
				<div className="flex flex-grow flex-row flex-nowrap gap-3">
					<BaseTextField label="Lot Number" value={selectedItem.LotNo} />
					<BaseTextField label="Barcode" value={selectedItem.Barcode} />
					<BaseTextField label="Expiration Date" value={formatDateTime(selectedItem.ItemExpiry)} />
					<BaseTextField label="Remaining Quantity" value={selectedItem.RemainingQty} />
				</div>

				<div className="mt-3 mb-3 float-right">
					<AddButton onClick={() => setOpenDialog(true)}>Add Unit Dosing</AddButton>
				</div>

				<BaseTable
					items={data}
					loading={loading}
					pagination={false}
					headers={[
						{
							label: 'Main Item Information',
							value: [
								{ label: 'Created By', value: 'CreatedBy' },
								{ label: 'Created Date', value: (data) => formatDateTime(data.CreatedDateTime) },
								{ label: 'Main Item Qty', value: 'MainItemQty' },
								{ label: 'Dosing Conversion', value: 'UnitConversionQty' },
							],
						},
						{
							label: 'Dosing Item Information',
							value: [
								{ label: 'Dosing Item', value: 'DosingItemDescription' },
								{ label: 'Unit', value: 'DosingItemUnit' },
								{ label: 'Dosing Item Qty', value: 'DosingItemQty' },
								{ label: 'Cost', value: 'DosingUnitPrice' },
								{ label: 'Expiration Date', value: (data) => formatDateTime(data.DosingExpirationDate) },
							],
						},
					]}
					// additionalHeaders={[
					// 	{ label: 'Created Date', value: (data) => formatDateTime(data.CreatedDateTime) },
					// 	{ label: 'Created By', value: 'CreatedBy' },
					// 	{ label: 'Main Item Qty', value: 'MainItemQty' },
					// 	{ label: 'Dosing Conversion', value: 'UnitConversionQty' },
					// 	{ label: 'Dosing Item', value: 'DosingItemDescription' },
					// 	{ label: 'Unit', value: 'DosingItemUnit' },
					// 	{ label: 'Dosing Item Qty', value: 'DosingItemQty' },
					// 	{ label: 'Cost', value: 'DosingUnitPrice' },
					// 	{ label: 'Expiration Date', value: (data) => formatDateTime(data.DosingExpirationDate) },
					// ]}
					rootHeight={600}
					actionColumn={(data) => <DeleteIconButton disabled={!data.isAllowVoid} />}
				/>
			</BaseDialog>
		</>
	);
};

export default UnitDosingDialog;
