import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const PurchaseOrderStatusPage = lazy(() => import('modules/mms/pages/PurchaseOrderStatus/PurchaseOrderStatusPage'));
const PurchaseOrderStatusPendingPage = lazy(
	() => import('modules/mms/pages/PurchaseOrderStatus/pages/PurchaseOrderStatusPending/PurchaseOrderStatusPendingPage')
);
const PurchaseOrderStatusApprovedPage = lazy(
	() => import('modules/mms/pages/PurchaseOrderStatus/pages/PurchaseOrderStatusApproved/PurchaseOrderStatusApprovedPage')
);
const PurchaseOrderStatusRejectedPage = lazy(
	() => import('modules/mms/pages/PurchaseOrderStatus/pages/PurchaseOrderStatusRejected/PurchaseOrderStatusRejectedPage')
);

const route: RouteObject = {
	path: 'purchase-order-status',
	element: <PurchaseOrderStatusPage />,
	children: [
		{ index: true, element: <PurchaseOrderStatusPendingPage /> },
		{ path: 'approved', element: <PurchaseOrderStatusApprovedPage /> },
		{ path: 'rejected', element: <PurchaseOrderStatusRejectedPage /> },
	],
};

export default route;
