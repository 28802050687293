import { RouteObject } from 'react-router-dom';

import ViewPatientModel from 'modules/his/pages/ViewPatient/types/view-patient.model';
import { lazy } from 'react';

const PaymentAllocationHospitalChargesPage = lazy(
	() =>
		import(
			'modules/his/pages/ViewPatient/pages/AccountSummary/pages/PaymentAllocation/pages/PaymentAllocationHospitalCharges/PaymentAllocationHospitalChargesPage'
		)
);

const PaymentAllocationRoomChargesPage = lazy(
	() =>
		import(
			'modules/his/pages/ViewPatient/pages/AccountSummary/pages/PaymentAllocation/pages/PaymentAllocationRoomCharges/PaymentAllocationRoomChargesPage'
		)
);

const PaymentAllocationDoctorChargesPage = lazy(
	() =>
		import(
			'modules/his/pages/ViewPatient/pages/AccountSummary/pages/PaymentAllocation/pages/PaymentAllocationDoctorCharges/PaymentAllocationDoctorChargesPage'
		)
);

const PaymentAllocationDebitAdjustmentPage = lazy(
	() =>
		import(
			'modules/his/pages/ViewPatient/pages/AccountSummary/pages/PaymentAllocation/pages/PaymentAllocationDebitAdjustment/PaymentAllocationDebitAdjustmentPage'
		)
);

const PaymentAllocationPromissoryInterestPage = lazy(
	() =>
		import(
			'modules/his/pages/ViewPatient/pages/AccountSummary/pages/PaymentAllocation/pages/PaymentAllocationPromissoryInterest/PaymentAllocationPromissoryInterestPage'
		)
);

const nursingProps: ViewPatientModel = {
	module: 'Nursing Services',
	path: 'nursing-services',
};

const routes: RouteObject[] = [
	{ index: true, element: <PaymentAllocationHospitalChargesPage {...nursingProps} /> },
	{ path: 'room-charges', element: <PaymentAllocationRoomChargesPage {...nursingProps} /> },
	{ path: 'doctor-charges', element: <PaymentAllocationDoctorChargesPage {...nursingProps} /> },
	{ path: 'debit-adjustment', element: <PaymentAllocationDebitAdjustmentPage {...nursingProps} /> },
	{ path: 'promissory-interest', element: <PaymentAllocationPromissoryInterestPage {...nursingProps} /> },
];

export default routes;
