import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const PromissoryPaymentPage = lazy(
	() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/PatientPromissory/pages/PromissoryPayment/PromissoryPaymentPage')
);
const PatientPromissoryAccountSummaryPage = lazy(
	() =>
		import(
			'modules/his/pages/ViewPatient/pages/AccountSummary/pages/PatientPromissory/pages/PatientPromissoryAccountSummary/PatientPromissoryAccountSummaryPage'
		)
);

const routes: RouteObject[] = [
	{ index: true, element: <PromissoryPaymentPage /> },
	{ path: 'account-summary', element: <PatientPromissoryAccountSummaryPage /> },
];

export default routes;
