import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const EmployeeDashboardMainPage = lazy(() => import('modules/hris/pages/EmployeeDashboard/pages/EmployeeDashboardMainPage'));
const EmployeeDashboardPage = lazy(() => import('modules/hris/pages/EmployeeDashboard/EmployeeDashboardPage'));
const EmployeeAnnouncementsPage = lazy(() => import('modules/hris/pages/EmployeeDashboard/pages/EmployeeAnnouncementsPage'));
const EmployeeEventsPage = lazy(() => import('modules/hris/pages/EmployeeDashboard/pages/EmployeeDashboardMainPage'));

const route: RouteObject[] = [
	{
		element: <EmployeeDashboardPage />,
		children: [
			{
				index: true,
				element: <EmployeeDashboardMainPage />,
			},
			{
				path: `announcements/:slug`,
				element: <EmployeeAnnouncementsPage />,
			},
			{
				path: 'events',
				element: <EmployeeEventsPage />,
			},
		],
	},
];
export default route;
