import React from 'react';

import BaseButton, { BaseButtonProps } from './BaseButton';
import SearchIcon from '@mui/icons-material/Search';

const SearchButton: React.FC<BaseButtonProps> = ({ children, ...props }) => {
	return (
		<BaseButton startIcon={<SearchIcon />} {...props}>
			{children ?? 'SEARCH'}
		</BaseButton>
	);
};

export default SearchButton;
