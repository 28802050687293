import layoutStore from 'stores/layout-store';

export const SIDEBAR_WIDTH = 260;
export const SIDEBAR_WIDTH_MINI = 65;
export const APP_BAR_HEIGHT = 64;
export const FOOTER_HEIGHT = 40;
export const BREADCRUMB_HEIGHT = 40;
export const BODY_PADDING = 25;
export const BODY_PADDING_MOBILE = 20;
export const VIEW_PATIENT_SIDEBAR_WIDTH = 290;
export const VIEW_PATIENT_SIDEBAR_WIDTH_MINI = 60;
export const ADMISSION_VIEW_PATIENT_SIDEBAR_WIDTH = 185;
export const PAGE_MAX_HEIGHT = `calc(100vh - ${APP_BAR_HEIGHT}px - ${BREADCRUMB_HEIGHT}px - ${FOOTER_HEIGHT}px - (${BODY_PADDING}px * 2))`;

export const viewPatientMaxHeight = (additional?: string) =>
	`calc(100vh - ${APP_BAR_HEIGHT}px - ${BREADCRUMB_HEIGHT}px - ${FOOTER_HEIGHT}px - (${BODY_PADDING}px * 2) - (36.7px * 2) - (16px *2) - (8px * 2) ${
		additional ? `- ${additional}` : ''
	})`;

export const getAccountSummaryMaxTableWidth = () => {
	if (layoutStore.sideBarState && !layoutStore.viewPatientSideBarMini) {
		return `calc(100vw - ${SIDEBAR_WIDTH}px - ${VIEW_PATIENT_SIDEBAR_WIDTH}px - ${ADMISSION_VIEW_PATIENT_SIDEBAR_WIDTH}px - (25px * 3) - 1rem - 8px)`;
	} else if (!layoutStore.sideBarState && !layoutStore.viewPatientSideBarMini) {
		return `calc(100vw - ${SIDEBAR_WIDTH_MINI}px - ${VIEW_PATIENT_SIDEBAR_WIDTH}px - ${ADMISSION_VIEW_PATIENT_SIDEBAR_WIDTH}px - (25px * 3) - 1rem - 8px)`;
	} else if (layoutStore.sideBarState && layoutStore.viewPatientSideBarMini) {
		return `calc(100vw - ${SIDEBAR_WIDTH}px - ${VIEW_PATIENT_SIDEBAR_WIDTH_MINI}px - ${ADMISSION_VIEW_PATIENT_SIDEBAR_WIDTH}px - (25px * 3) - 1rem - 8px)`;
	} else if (!layoutStore.sideBarState && layoutStore.viewPatientSideBarMini) {
		return `calc(100vw - ${SIDEBAR_WIDTH_MINI}px - ${VIEW_PATIENT_SIDEBAR_WIDTH_MINI}px - ${ADMISSION_VIEW_PATIENT_SIDEBAR_WIDTH}px - (25px * 3) - 1rem - 8px)`;
	} else {
		return ``;
	}
};

export const getViewPatientMaxTableWidth = () => {
	if (layoutStore.sideBarState && !layoutStore.viewPatientSideBarMini) {
		return `calc(100vw - ${SIDEBAR_WIDTH}px - ${VIEW_PATIENT_SIDEBAR_WIDTH}px - (25px * 4) - 1rem)`;
	} else if (!layoutStore.sideBarState && !layoutStore.viewPatientSideBarMini) {
		return `calc(100vw - ${SIDEBAR_WIDTH_MINI}px - ${VIEW_PATIENT_SIDEBAR_WIDTH}px - (25px * 4) - 1rem)`;
	} else if (layoutStore.sideBarState && layoutStore.viewPatientSideBarMini) {
		return `calc(100vw - ${SIDEBAR_WIDTH}px - ${VIEW_PATIENT_SIDEBAR_WIDTH_MINI}px - (25px * 4) - 1rem)`;
	} else if (!layoutStore.sideBarState && layoutStore.viewPatientSideBarMini) {
		return `calc(100vw - ${SIDEBAR_WIDTH_MINI}px - ${VIEW_PATIENT_SIDEBAR_WIDTH_MINI}px - (25px * 4) - 1rem)`;
	} else {
		return ``;
	}
};

export const dialogBodyMaxHeight = (additional?: string) =>
	`calc(100vh - (32px * 2) - 60px - (24px * 2)${additional ? `- ${additional}` : ''})`;

export const VIEW_PATIENT_MAX_TABLE_HEIGHT = `calc(100vh - ${APP_BAR_HEIGHT}px - ${BREADCRUMB_HEIGHT}px - ${FOOTER_HEIGHT}px - (${BODY_PADDING}px * 2) - (36.7px * 2) - (8px * 2) - (16px * 2))`;
export const VIEW_PATIENT_MAX_TABLE_HEIGHT_WITH_SEARCH = `calc(100vh - ${APP_BAR_HEIGHT}px - ${BREADCRUMB_HEIGHT}px - ${FOOTER_HEIGHT}px - (${BODY_PADDING}px * 2) - (36.7px * 2) - (8px * 3) - (16px * 2) - 19.7px - 110px)`;
