import InfectionControlModel from 'modules/his/pages/InfectionControl/types/infection-control.model';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const InfectionControlPage = lazy(() => import('modules/his/pages/InfectionControl/InfectionControlPage'));
const InfectionControlMainPage = lazy(
	() => import('modules/his/pages/InfectionControl/pages/InfectionControlMainPage/InfectionControlMainPage')
);

const infectionControlModelProps: InfectionControlModel = {
	module: 'Infection Control',
	path: 'infection-control',
};

const route: RouteObject[] = [
	{
		element: <InfectionControlPage {...infectionControlModelProps} />,
		children: [
			{
				index: true,
				element: <InfectionControlMainPage {...infectionControlModelProps} />,
			},
		],
	},
];

export default route;
