import addHours from 'date-fns/addHours';

export function formatDate(date: Date | string | null, type: 'utc' | null = 'utc') {
	if (!date) return null;

	if (type === 'utc') {
		let d = new Date(date),
			month = '' + (d.getUTCMonth() + 1),
			day = '' + d.getUTCDate(),
			year = d.getUTCFullYear();

		if (month?.length < 2) month = '0' + month;
		if (day?.length < 2) day = '0' + day;

		return [month, day, year].join('/');
	} else {
		let d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month?.length < 2) month = '0' + month;
		if (day?.length < 2) day = '0' + day;

		return [month, day, year].join('/');
	}

	// if (month?.length < 2) month = '0' + month;
	// if (day?.length < 2) day = '0' + day;

	// return [month, day, year].join('/');
}

export function dateFormat(date: Date | null | undefined | string, options?: Intl.DateTimeFormatOptions) {
	if (!date) return null;
	if (typeof date === 'string') {
		date = new Date(date);
	}

	return date.toLocaleDateString('en-us', {
		day: '2-digit',
		month: 'numeric',
		year: 'numeric',
		timeZone: 'America/Danmarkshavn',
		...options,
	});
}

export function timeFormat(time: Date | string | null, options?: Intl.DateTimeFormatOptions) {
	if (!time) return null;
	if (typeof time === 'string') {
		time = new Date(time);
	}

	return time.toLocaleTimeString('en-us', {
		hour: 'numeric',
		minute: 'numeric',
		timeZone: 'America/Danmarkshavn',
		...options,
	});
}

export function dateTimeFormat(dateTime?: Date | string | null, options?: Intl.DateTimeFormatOptions) {
	if (!dateTime) return null;
	if (typeof dateTime === 'string') {
		dateTime = new Date(dateTime);
	}

	return dateTime.toLocaleDateString('en-us', {
		day: '2-digit',
		month: 'numeric',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		timeZone: 'America/Danmarkshavn',
		...options,
	});
}

export function formatDateTime(date: Date | string | null | undefined, time?: Date | string | null | undefined) {
	if (!date) return null;
	let dateTime;

	if (time) {
		date = new Date(date);
		time = new Date(time);
		dateTime = new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate(),
			time.getHours(),
			time.getMinutes(),
			time.getSeconds()
			// time.getMilliseconds()
		);
	} else {
		dateTime = new Date(date);
	}

	return dateTime.toLocaleTimeString('en-us', {
		month: '2-digit',
		day: '2-digit',
		year: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		hour12: true,
		timeZone: 'America/Danmarkshavn',
	});
}

export function formatDateWithTime(date: Date | string | null, time: Date | string | null) {
	if (!date) return null;
	let d = new Date(date),
		month = '' + (d.getUTCMonth() + 1),
		day = '' + d.getUTCDate(),
		year = d.getUTCFullYear();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return [month, day, year].join('/') + ' ' + (time == null ? '' : new Date(date + ' ' + time).toLocaleTimeString());
}

export function formatTime(date: Date | string | null) {
	if (!date) return null;
	if (typeof date === 'string') {
		date = new Date(date);
	}

	let hours = date.getHours().toString();
	let minutes = date.getMinutes().toString();
	let seconds = date.getSeconds().toString();

	if (hours.length < 2) hours = '0' + hours;
	if (minutes.length < 2) minutes = '0' + minutes;
	if (seconds.length < 2) seconds = '0' + seconds;

	return `${hours}:${minutes}:${seconds}`;
}

export function formatTimeNoSeconds(date: Date | string | null) {
	if (!date) return null;
	if (typeof date === 'string') {
		date = new Date(date);
	}

	let hours = date.getHours().toString();
	let minutes = date.getMinutes().toString();

	if (hours.length < 2) hours = '0' + hours;
	if (minutes.length < 2) minutes = '0' + minutes;

	return `${hours}:${minutes}:00`;
}

export function timeFormat12(time: string) {
	if (!time) return null;
	const [hours, minutes] = time.split(':');
	const ampm = parseInt(hours) >= 12 ? 'PM' : 'AM';

	return `${parseInt(hours) > 12 ? parseInt(hours) - 12 : hours === '00' ? '12' : hours}:${minutes} ${ampm}`;
}

export function convertToSqlDate(date?: string | Date | null, separator?: string) {
	if (!date) return;
	if (typeof date === 'string') {
		date = new Date(date);
	}
	// return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
	return (
		date.getFullYear() +
		(separator || '-') +
		('0' + (date.getMonth() + 1)).slice(-2) +
		(separator || '-') +
		('0' + date.getDate()).slice(-2)
	);
}

export function convertToSqlDateTime(date?: Date | null, time?: Date | null) {
	if (!date) return;
	if (date instanceof Date === false) {
		date = new Date(date);
	}

	if (time instanceof Date === false) {
		time = new Date(time ?? date);
	}
	return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}T${
		time?.toTimeString().split(' ')[0]
	}`;
}

export function subtractFromDate(days: number) {
	if (!days) return;
	return new Date(new Date().setDate(new Date().getUTCDate() - days));
}

export function getAge(dateString: string) {
	if (!dateString) return null;
	const today = new Date();
	const birthDate = new Date(dateString);
	let age = today.getUTCFullYear() - birthDate.getUTCFullYear();
	const m = today.getUTCMonth() - birthDate.getUTCMonth();
	if (m < 0 || (m === 0 && today.getUTCDate() < birthDate.getUTCDate())) {
		age--;
	}
	return age;
}

export const addMinutes = function (date: Date, minutes: number) {
	return new Date(date.getTime() + minutes * 60000);
};

export const minusDays = function (date: Date, days: number) {
	return new Date(date.setDate(date.getDate() - days));
};

export const dateDiffInDays = (startDate: Date, endDate: Date) => {
	const firstDate = new Date(startDate.setHours(0, 0, 0, 0));
	const secondDate = new Date(endDate.setHours(0, 0, 0, 0));
	const timeDiff = secondDate.getTime() - firstDate.getTime();
	return timeDiff / (1000 * 3600 * 24);
};

export const convertToSqlDateTimeC = (date: Date | string | null) => {
	if (!date) return '';

	if (date instanceof Date === false || typeof date === 'string') {
		date = new Date(date);
	}

	date = addHours(date, 8);

	return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}T${
		date.toTimeString().split(' ')[0]
	}`;
};

export function ComputeAgeYMD(days: Date) {
	let age: string | number | {} = '1';
	let year, month, day;

	year = new Date().getFullYear() - new Date(days).getFullYear();

	if (new Date().getMonth() + 1 > new Date(days).getMonth() + 1) {
		month = Math.abs(new Date().getMonth() + 1 - (new Date(days).getMonth() + 1));
	} else if (new Date().getMonth() === new Date(days).getMonth()) {
		month = 0;
	} else {
		month = new Date().getMonth() + 1 - (new Date(days).getMonth() + 1) + 12;
	}

	day = new Date().getDate() >= new Date(days).getDate() ? new Date().getDate() - new Date(days).getDate() : 0;

	age = year + 'Y ' + month + 'M ' + day + 'D';

	return age;
}
