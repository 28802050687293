import pathManualBillsModel from 'modules/ams/pages/ManualBills/types/path-manual-bills';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const ManualBillsPage = lazy(() => import('modules/ams/pages/ManualBills/ManualBills'));
const ManualBillsMainPage = lazy(() => import('modules/ams/pages/ManualBills/pages/ManualBillsMainPage'));
const  pathManualBills: pathManualBillsModel = {
	module: 'Manual-Bills',
	path: 'manual-bills',
};

const route: RouteObject[] = [
	{
		element: <ManualBillsPage {...pathManualBills} />,
		children: [
			{
				index: true,
				element: <ManualBillsMainPage {...pathManualBills} />,
			},
		],
	},
];

export default route;
