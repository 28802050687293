import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import applicationRoutes from './pcs-application-routes';
import outrightDeductionsRoutes from './pcs-outright-deductions-routes';
import phicTransmittalRoutes from './pcs-phic-transmittal-ruotes';

const PCSLayout = lazy(() => import('modules/pcs/layout/PCSLayout'));
const ApplicationPage = lazy(() => import('modules/pcs/pages/Application/ApplicationPage'));
const DeductionUploadPage = lazy(() => import('modules/pcs/pages/DeductionUpload/DeductionUploadPage'));
const CheckEClaimsStatusPage = lazy(() => import('modules/pcs/pages/CheckEClaimsStatus/CheckEClaimsStatusPage'));
const PatientListPage = lazy(() => import('modules/pcs/pages/PatientList/PatientListPage'));
const HCISettingsPage = lazy(() => import('modules/pcs/pages/HCISettings/HCISettingsPage'));
const PHICClaimsPage = lazy(() => import('modules/pcs/pages/PHICClaims/PHICClaimsPage'));
const PHICPFBalancePage = lazy(() => import('modules/pcs/pages/PHICPFBalance/PHICPFBalancePage'));

const route: RouteObject = {
	path: 'pcs',
	element: <PCSLayout />,
	children: [
		{ path: '', element: <ApplicationPage />, children: applicationRoutes },
		outrightDeductionsRoutes,
		{ path: 'deduction-upload', element: <DeductionUploadPage /> },
		{ path: 'check-e-claims-status', element: <CheckEClaimsStatusPage /> },
		{ path: 'patient-list', element: <PatientListPage /> },
		phicTransmittalRoutes,
		{ path: 'hci-settings', element: <HCISettingsPage /> },
		{ path: 'phic-claims', element: <PHICClaimsPage /> },
		{ path: 'phic-pf-balance', element: <PHICPFBalancePage /> },
	],
};

export default route;
