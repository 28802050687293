import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const SuppliersPage = lazy(() => import('modules/mms/pages/Suppliers/SuppliersPage'));
const SuppliersMainPage = lazy(() => import('modules/mms/pages/Suppliers/pages/SuppliersMainPage'));

const route: RouteObject = {
	path: 'suppliers',
	element: <SuppliersPage />,
	children: [{ index: true, element: <SuppliersMainPage /> }],
};

export default route;
