import consultantsModel from 'modules/his/pages/Consultants/types/consultants.model';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const ConsultantsPage = lazy(() => import('modules/his/pages/Consultants/ConsultantsPage'));
const ConsultantsMainPage = lazy(() => import('modules/his/pages/Consultants/pages/ConsultantsMainPage/ConsultantsMainPage'));

const consultantsProps: consultantsModel = {
	module: 'Consultants',
	path: 'consultants',
};

const route: RouteObject[] = [
	{
		element: <ConsultantsPage {...consultantsProps} />,
		children: [
			{
				index: true,
				element: <ConsultantsMainPage {...consultantsProps} />,
			},
		],
	},
];

export default route;
