import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import BaseButton, { BaseButtonProps } from '../BaseButton';

const DeleteIconButton: React.FC<BaseButtonProps> = (props) => {
	return (
		<BaseButton backgroundColor="danger" icon {...props}>
			<DeleteIcon />
		</BaseButton>
	);
};

export default DeleteIconButton;
