import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const PHICTransmittalPage = lazy(() => import('modules/pcs/pages/PHICTransmittal/PHICTransmittalPage'));
const PHICTransmittalTransmittalListPage = lazy(
	() => import('modules/pcs/pages/PHICTransmittal/pages/TransmittalList/PHICTransmittalTransmittalListPage')
);
const PHICTransmittalPatientListPage = lazy(
	() => import('modules/pcs/pages/PHICTransmittal/pages/PatientList/PHICTransmittalPatientListPage')
);

const route: RouteObject = {
	path: 'phic-transmittal',
	element: <PHICTransmittalPage />,
	children: [
		{ index: true, element: <PHICTransmittalTransmittalListPage /> },
		{ path: 'patient-list', element: <PHICTransmittalPatientListPage /> },
	],
};

export default route;
