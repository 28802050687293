import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const ApplicationApplicationPage = lazy(() => import('modules/pcs/pages/Application/pages/Application/ApplicationApplicationPage'));
const ApplicationWithOutrightDeductionPage = lazy(
	() => import('modules/pcs/pages/Application/pages/ApplicationWithOutrightDeduction/ApplicationWithOutrightDeductionPage')
);
const ApplicationVoidedPage = lazy(() => import('modules/pcs/pages/Application/pages/ApplicationVoided/ApplicationVoidedPage'));

const routes: RouteObject[] = [
	{ path: '', element: <ApplicationApplicationPage /> },
	{ path: 'with-outright-deduction', element: <ApplicationWithOutrightDeductionPage /> },
	{ path: 'voided', element: <ApplicationVoidedPage /> },
];

export default routes;
