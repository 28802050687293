import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const DonationsPage = lazy(() => import('modules/mms/pages/Donations/DonationsPage'));
const DonationsPendingPage = lazy(() => import('modules/mms/pages/Donations/pages/DonationsPending/DonationsPendingPage'));
const DonationsApprovedPage = lazy(() => import('modules/mms/pages/Donations/pages/DonationsApproved/DonationsApprovedPage'));
const DonationsVoidedPage = lazy(() => import('modules/mms/pages/Donations/pages/DonationsVoided/DonationsVoidedPage'));

const route: RouteObject = {
	path: 'donations',
	element: <DonationsPage />,
	children: [
		{ index: true, element: <DonationsPendingPage /> },
		{ path: 'approved', element: <DonationsApprovedPage /> },
		{ path: 'voided', element: <DonationsVoidedPage /> },
	],
};

export default route;
