import { makeAutoObservable } from 'mobx';
import Swal from 'sweetalert2';

export interface ResponseDialogProps {
	status: boolean;
	title: string;
	content: string;
	btnText: string;
	icon: React.ReactNode | string;
	type: 'success' | 'error' | 'warning' | 'info' | '';
	duration: number | 'unlimited';
	dialogType: 'response' | 'confirmation';
	responseType: 'dialog' | 'toast';
}
export class DialogStoreClass {
	response: ResponseDialogProps = {
		status: false,
		title: '',
		content: '',
		btnText: '',
		icon: '',
		type: '',
		duration: 6000,
		dialogType: 'response',
		responseType: 'toast',
	};
	constructor() {
		makeAutoObservable(this);
	}
	update(payload: Partial<ResponseDialogProps>) {
		Object.keys(this.response).forEach((property) => {
			if ((payload as any)[property] !== undefined) {
				(this.response as any)[property] = (payload as any)[property];
			}
		});
	}
	success(message: string, config?: Partial<ResponseDialogProps>) {
		Swal.fire({
			icon: 'success',
			title: 'Success',
			// text: message,
			showConfirmButton: false,
			timer: 3000,
		});
		// this.response.status = true;
		// this.response.type = 'success';
		// this.response.content = message;
		// this.update(config ?? {});
	}
	info(message: string, config?: Partial<ResponseDialogProps>) {
		this.response.status = true;
		this.response.type = 'info';
		this.response.content = message;
		this.update(config ?? {});
	}
	error(message: any, config?: Partial<ResponseDialogProps>) {
		Swal.fire({
			icon: 'error',
			title: 'Error',
			text: message?.code === 'ESOCKET' ? 'Connection Error' : message,
			showConfirmButton: false,
			timer: 3000,
		});
		// this.response.status = true;
		// this.response.type = 'error';
		// this.response.content = message;
		// this.update(config ?? {});
	}
	warning(message: string, config?: Partial<ResponseDialogProps>) {
		Swal.fire({
			icon: 'warning',
			title: 'Warning',
			text: message,
			showConfirmButton: false,
			timer: 3000,
		});
		// this.response.status = true;
		// this.response.type = 'warning';
		// this.response.content = message;
		// this.update(config ?? {});
	}
	close() {
		this.response.status = false;
	}
}
export const DialogStore = new DialogStoreClass();
