import hmoGuarantorsModel from 'modules/his/pages/HMOGuarantors/types/hmo-guarantors.model';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const HMOGuarantorsPage = lazy(() => import('modules/his/pages/HMOGuarantors/HMOGuarantorsPage'));
const HMOGuarantorsMainPage = lazy(() => import('modules/his/pages/HMOGuarantors/pages/HMOGuarantorsMainPage/HMOGuarantorsMainPage'));

const hmoGuarantorsProps: hmoGuarantorsModel = {
	module: 'HMO/Guarantors',
	path: 'hmo-guarantors',
};

const route: RouteObject[] = [
	{
		element: <HMOGuarantorsPage {...hmoGuarantorsProps} />,
		children: [
			{
				index: true,
				element: <HMOGuarantorsMainPage {...hmoGuarantorsProps} />,
			},
		],
	},
];

export default route;
