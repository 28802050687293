import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import admissionRoutes from './his-admission-routes';
import nursingServices from './his-nursing-services-routes';
import infrastructureRoutes from './his-infrastructure-routes';
import itemsAndServicesRoutes from './his-items-and-services-routes';
import icd10CodeRoutes from './his-icd10-codes-routes';
import hmoGuarantorsRoutes from './his-hmo-guarantors-routes';
import suppliersRoutes from './his-suppliers-routes';
import consultantsRoutes from './his-consultants-routes';
import patientRecordsRoutes from './his-patient-records-routes';
import medicalRecordsRoutes from './his-medical-records-routes';
import infectionControlRoutes from './his-infection-control-routes';
import postProcessingRoutes from './his-post-processing-routes';
import RenalCareRoutes from './renal-care-routes';
import AncillaryServicesRoutes from './his-ancillary-routes';
import operatingRoomRoutes from './his-operating-room-routes';

const HISLayout = lazy(() => import('modules/his/layout/HISLayout'));

const CashierPage = lazy(() => import('modules/his/pages/Cashier/CashierPage'));
const BillingPage = lazy(() => import('modules/his/pages/Billing/BillingPage'));
const DashboardPage = lazy(() => import('modules/his/pages/Dashboard/HISDashboardPage'));
const ExaminationsPage = lazy(() => import('modules/his/pages/Examinations/ExaminationsPage'));
// const MedicalRecordsPage = lazy(() => import('modules/his/pages/MedicalRecords/MedicalRecordsPage'));
const LISIntegrationPage = lazy(() => import('modules/his/pages/LISIntegration/LISIntegrationPage'));
const DietaryServicesPage = lazy(() => import('modules/his/pages/DietaryServices/DietaryServicesPage'));
const InformationDeskPage = lazy(() => import('modules/his/pages/InformationDesk/InformationDeskPage'));

const CreditAndCollectionPage = lazy(() => import('modules/his/pages/CreditAndCollection/CreditAndCollectionPage'));
const MaintenancePage = lazy(() => import('modules/his/pages/Maintenance/MaintenancePage'));

const route: RouteObject = {
	path: 'his',
	element: <HISLayout />,
	children: [
		{
			index: true,
			element: <DashboardPage />,
		},
		{
			path: 'admission',
			children: admissionRoutes,
		},
		{
			path: 'nursing-services',
			children: nursingServices,
		},
		{
			path: 'dietary-services',
			element: <DietaryServicesPage />,
		},
		{
			path: 'renal-care',
			children: RenalCareRoutes,
		},
		{
			path: 'ancillary-services',
			children: AncillaryServicesRoutes,
		},
		{
			path: 'infection-control',
			children: infectionControlRoutes,
		},
		{
			path: 'examinations',
			element: <ExaminationsPage />,
		},
		{
			path: 'lis-integration',
			element: <LISIntegrationPage />,
		},
		{
			path: 'cashier',
			element: <CashierPage />,
		},
		{
			path: 'billing',
			element: <BillingPage />,
		},
		{
			path: 'patient-post-processing',
			children: postProcessingRoutes,
		},
		{
			path: 'credit-and-collection',
			element: <CreditAndCollectionPage />,
		},
		{
			path: 'information-desk',
			element: <InformationDeskPage />,
		},
		{
			path: 'medical-records',
			children: medicalRecordsRoutes,
		},
		{
			path: 'Maintenance',
			element: <MaintenancePage />,
		},
		{
			path: 'patient-records',
			children: patientRecordsRoutes,
		},
		{
			path: 'consultants',
			children: consultantsRoutes,
		},
		{
			path: 'infrastructure',
			children: infrastructureRoutes,
		},
		{
			path: 'hmo-guarantors',
			children: hmoGuarantorsRoutes,
		},
		{
			path: 'icd10-codes',
			children: icd10CodeRoutes,
		},
		{
			path: 'items-and-services',
			children: itemsAndServicesRoutes,
		},
		{
			path: 'suppliers',
			children: suppliersRoutes,
		},
		{
			path: 'operating-room',
			children: operatingRoomRoutes,
		},
	],
};

export default route;
