import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import ViewPatientModel from 'modules/his/pages/ViewPatient/types/view-patient.model';

const HMOAllocationSummaryPage = lazy(
	() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/HMOAllocation/pages/HMOAllocationSummary/HMOAllocationSummaryPage')
);

const HMOAllocationAllocationPage = lazy(
	() =>
		import(
			'modules/his/pages/ViewPatient/pages/AccountSummary/pages/HMOAllocation/pages/HMOAllocationAllocation/HMOAllocationAllocationPage'
		)
);

const HMOAllocationItemsAndServicesPage = lazy(
	() =>
		import(
			'modules/his/pages/ViewPatient/pages/AccountSummary/pages/HMOAllocation/pages/HMOAllocationAllocation/pages/HMOAllocationItemsAndServices/HMOAllocationItemsAndServicesPage'
		)
);

const HMOAllocationRoomPage = lazy(
	() =>
		import(
			'modules/his/pages/ViewPatient/pages/AccountSummary/pages/HMOAllocation/pages/HMOAllocationAllocation/pages/HMOAllocationRoom/HMOAllocationRoomPage'
		)
);

const HMOAllocationDoctorPage = lazy(
	() =>
		import(
			'modules/his/pages/ViewPatient/pages/AccountSummary/pages/HMOAllocation/pages/HMOAllocationAllocation/pages/HMOAllocationDoctor/HMOAllocationDoctorPage'
		)
);

const HMOAllocationAdjustmentPage = lazy(
	() =>
		import(
			'modules/his/pages/ViewPatient/pages/AccountSummary/pages/HMOAllocation/pages/HMOAllocationAllocation/pages/HMOAllocationAdjustment/HMOAllocationAdjustmentPage'
		)
);

const nursingProps: ViewPatientModel = {
	module: 'Nursing Services',
	path: 'nursing-services',
};

const routes: RouteObject[] = [
	{ index: true, element: <HMOAllocationSummaryPage /> },
	{
		path: 'allocation',
		element: <HMOAllocationAllocationPage {...nursingProps} />,
		children: [
			{ index: true, element: <HMOAllocationItemsAndServicesPage /> },
			{ path: 'rooms', element: <HMOAllocationRoomPage /> },
			{ path: 'doctors', element: <HMOAllocationDoctorPage /> },
			{ path: 'adjustment', element: <HMOAllocationAdjustmentPage /> },
		],
	},
];

export default routes;
