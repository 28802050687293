import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const OutrightDeductionsPage = lazy(() => import('modules/pcs/pages/OutrightDeductions/OutrightDeductionsPage'));
const OutrightDeductionsComputedAllocationPage = lazy(
	() => import('modules/pcs/pages/OutrightDeductions/OutrightDeductionsComputedAllocation/OutrightDeductionsComputedAllocationPage')
);
const OutrightDeductionsWithTransmittalPage = lazy(
	() => import('modules/pcs/pages/OutrightDeductions/OutrightDeductionsWithTransmittal/OutrightDeductionsWithTransmittalPage')
);
const OutrightDeductionsWithPaymentPage = lazy(
	() => import('modules/pcs/pages/OutrightDeductions/OutrightDeductionsWithPayment/OutrightDeductionsWithPaymentPage')
);
const OutrightDeductionsVoidedPage = lazy(
	() => import('modules/pcs/pages/OutrightDeductions/OutrightDeductionsVoided/OutrightDeductionsVoidedPage')
);

const route: RouteObject = {
	path: 'outright-deductions',
	element: <OutrightDeductionsPage />,
	children: [
		{ path: '', element: <OutrightDeductionsComputedAllocationPage /> },
		{ path: 'with-transmittal', element: <OutrightDeductionsWithTransmittalPage /> },
		{ path: 'with-payment', element: <OutrightDeductionsWithPaymentPage /> },
		{ path: 'voided', element: <OutrightDeductionsVoidedPage /> },
	],
};

export default route;
