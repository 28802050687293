import { useEffect, useState } from 'react';
import patientStore from 'stores/patient-store';
import PatientRegModel from 'types/patient-reg.model';

const usePatientStore = () => {
	const [patientReg, setPatientReg] = useState({} as PatientRegModel);

	useEffect(() => {
		setPatientReg(patientStore.patientReg);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [patientStore.patientReg]);

	return {
		patientReg,
		setPatientReg: patientStore.setPatientReg,
		patient: patientReg.patient,
		patientRegType: patientReg.patientreg_type_current,
		patientRegAccountStatus: patientReg.patientreg_account_status,
		patientRegDoctors: patientReg.patientreg_doctors,
		loading: patientStore.loading,
	};
};

export default usePatientStore;
