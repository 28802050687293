import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const InfrastructurePage = lazy(() => import('modules/his/pages/Infrastructure/InfrastructurePage'));

const route: RouteObject[] = [
	{
		index: true,
		element: <InfrastructurePage />,
	},
];

export default route;
