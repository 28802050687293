import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import PatientRecordsModel from 'modules/his/pages/PatientRecords/types/patient-records.model';

const PatientRecordsPage = lazy(() => import('modules/his/pages/PatientRecords/PatientRecordsPage'));
const PatientRecordsMainPage = lazy(() => import('modules/his/pages/PatientRecords/pages/PatientRecordsMainPage/PatientRecordsMainPage'));
const PatientRecordRegistrationPage = lazy(
	() => import('modules/his/pages/PatientRecords/pages/PatientRecordRegistration/PatientRecordRegistrationPage')
);
const MergeAccountPage = lazy(() => import('modules/his/pages/PatientRecords/pages/MergeAccount/MergeAccountPage'));
const ViewPatientRecordPage = lazy(() => import('modules/his/pages/PatientRecords/pages/ViewPatientRecord/ViewPatientRecordPage'));

const patientRecordsProps: PatientRecordsModel = {
	module: 'Patient Records',
	path: 'patient-records',
};

const route: RouteObject[] = [
	{
		element: <PatientRecordsPage {...patientRecordsProps} />,
		children: [
			{
				index: true,
				element: <PatientRecordsMainPage {...patientRecordsProps} />,
			},
			{
				path: 'new',
				element: <PatientRecordRegistrationPage {...patientRecordsProps} />,
			},
			{
				path: 'view-patient-record',
				element: <ViewPatientRecordPage {...patientRecordsProps} />,
			},
			{
				path: 'merge-account',
				element: <MergeAccountPage {...patientRecordsProps} />,
			},
		],
	},
];

export default route;
