import React from 'react';
import { HFormTextField, HFormTextFieldProps } from '@hybrain/mui.ui.final-form.h-form-text-field';

export type FormTextFieldProps = {} & HFormTextFieldProps;

const FormTextField: React.FC<FormTextFieldProps> = (props) => {
	return <HFormTextField size="small" {...props} />;
};

export default FormTextField;
