import React from 'react';
import { HTextField, HTextFieldProps } from '@hybrain/mui.ui.inputs.h-text-field';

const BaseTextField = ({ value, ...props }: HTextFieldProps) => {
	return (
		<HTextField
			size="small"
			fullWidth
			{...props}
			// value={value === undefined || value === null ? '' : value}
			value={value}
			InputLabelProps={{
				shrink: props.readOnly ? true : undefined,
				...props.InputLabelProps,
			}}
		/>
	);
};

export default BaseTextField;
