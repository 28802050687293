import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { BODY_MAX_HEIGHT, BODY_MAX_HEIGHT_MOBILE } from 'constants/layout';

const PageContainer = ({ children, ...props }: BoxProps & { children: React.ReactNode }) => {
	return (
		<Box
			display="grid"
			flexGrow={1}
			gap={1}
			gridTemplateRows="auto auto auto minmax(1px, 1fr)"
			{...props}
			// sx={{ maxHeight: BODY_MAX_HEIGHT_MOBILE, '@media screen and (min-width: 460px)': { maxHeight: BODY_MAX_HEIGHT }, ...props }}
		>
			{children}
		</Box>
	);
};

export default PageContainer;
