import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const ReceivingOfReplacementsPage = lazy(() => import('modules/mms/pages/ReceivingOfReplacements/ReceivingOfReplacementsPage'));
const PendingReplacements = lazy(() => import('modules/mms/pages/ReceivingOfReplacements/pages/PendingReplacements'));
const ApprovedReplacements = lazy(() => import('modules/mms/pages/ReceivingOfReplacements/pages/ApprovedReplacements'));
const VoidedReplacements = lazy(() => import('modules/mms/pages/ReceivingOfReplacements/pages/VoidedReplacements'));

const route: RouteObject = {
	path: 'receiving-of-replacements',
	element: <ReceivingOfReplacementsPage />,
	children: [
		{ index: true, element: <PendingReplacements /> },
		{ path: 'approved', element: <ApprovedReplacements /> },
		{ path: 'voided', element: <VoidedReplacements /> },
	],
};

export default route;
