import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const ReturnToSupplierPage = lazy(() => import('modules/mms/pages/ReturnToSupplier/ReturnToSupplierPage'));
const PendingReturns = lazy(() => import('modules/mms/pages/ReturnToSupplier/pages/PendingReturns'));
const ApprovedReturns = lazy(() => import('modules/mms/pages/ReturnToSupplier/pages/ApprovedReturns'));
const VoidedReturns = lazy(() => import('modules/mms/pages/ReturnToSupplier/pages/VoidedReturns'));

const route: RouteObject = {
	path: 'return-to-supplier',
	element: <ReturnToSupplierPage />,
	children: [
		{ index: true, element: <PendingReturns /> },
		{ path: 'approved', element: <ApprovedReturns /> },
		{ path: 'voided', element: <VoidedReturns /> },
	],
};

export default route;
