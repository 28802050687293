import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const PurchaseOrderApprovalLevel1Page = lazy(() => import('modules/mms/pages/PurchaseOrderApprovalLevel1/PurchaseOrderApprovalLevel1Page'));
const PurchaseOrderApprovalPendingPage = lazy(
	() => import('modules/mms/pages/PurchaseOrderApprovalLevel1/pages/PurchaseOrderApprovalPending/PurchaseOrderApprovalPendingPage')
);
const PurchaseOrderApprovalApprovedPage = lazy(
	() => import('modules/mms/pages/PurchaseOrderApprovalLevel1/pages/PurchaseOrderApprovalApproved/PurchaseOrderApprovalApprovedPage')
);
const PurchaseOrderApprovalRejectedPage = lazy(
	() => import('modules/mms/pages/PurchaseOrderApprovalLevel1/pages/PurchaseOrderApprovalRejected/PurchaseOrderApprovalRejectedPage')
);

const route: RouteObject = {
	path: 'purchase-order-approval-level-1',
	element: <PurchaseOrderApprovalLevel1Page />,
	children: [
		{ index: true, element: <PurchaseOrderApprovalPendingPage /> },
		{ path: 'approved', element: <PurchaseOrderApprovalApprovedPage /> },
		{ path: 'rejected', element: <PurchaseOrderApprovalRejectedPage /> },
	],
};

export default route;
