import ICD10CodesModel from 'modules/his/pages/ICD10Codes/types/icd10-codes.model';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const ICD10CodesPage = lazy(() => import('modules/his/pages/ICD10Codes/ICD10CodesPage'));
const ICD10CodesMainPage = lazy(() => import('modules/his/pages/ICD10Codes/pages/ICD10CodesMainPage/ICD10CodesMainPage'));

const icd10CodesProps: ICD10CodesModel = {
	module: 'ICD10 Codes',
	path: 'icd10-codes',
};

const route: RouteObject[] = [
	{
		element: <ICD10CodesPage {...icd10CodesProps} />,
		children: [
			{
				index: true,
				element: <ICD10CodesMainPage {...icd10CodesProps} />,
			},
		],
	},
];

export default route;
