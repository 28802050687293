import React from 'react';
import { HButton, HButtonProps } from '@hybrain/mui.ui.inputs.h-button';

export type BaseButtonProps = {} & HButtonProps;

const BaseButton = ({ sx, ...props }: BaseButtonProps) => {
	return <HButton sx={{ ...sx, flexShrink: 0 }} {...props} />;
};

export default BaseButton;
