import { useEffect, useState } from 'react';

const debounce = (fn: any, ms: number) => {
	let timer: any;
	return (_: any, ...args: any[]) => {
		clearTimeout(timer);
		timer = setTimeout((_: any) => {
			timer = null;
			fn.apply(this, args);
		}, ms);
	};
};

const useWindowDimension = () => {
	const [dimension, setDimension] = useState([window.innerWidth, window.innerHeight]);

	useEffect(() => {
		const debouncedResizeHandler = debounce(() => {
			setDimension([window.innerWidth, window.innerHeight]);
		}, 100); // 100ms
		window.addEventListener('resize', debouncedResizeHandler);
		return () => window.removeEventListener('resize', debouncedResizeHandler);
	}, []);
	return dimension;
};

export default useWindowDimension;
