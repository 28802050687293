import BaseTable from 'components/data-display/BaseTable';
import BaseDialog from 'components/feedback/BaseDialog';
import CheckIconButton from 'components/inputs/buttons/icons/CheckIconButton';
import SearchButton from 'components/inputs/buttons/SearchButton';
import SearchTextField from 'components/inputs/text-fields/SearchTextField';
import useApi from 'hooks/useApi';
import { useEffect, useState } from 'react';
import ItemModel from 'types/item.model';

interface UnitDosingSearchItemDialogProps {
	open: boolean;
	onClose: () => void;
	setSelectedItem: (data: ItemModel) => void;
}

const UnitDosingSearchItemDialog = ({ open, onClose, setSelectedItem }: UnitDosingSearchItemDialogProps) => {
	const [searchWord, setSearchWord] = useState<string>('');

	const { callApi, data, loading } = useApi<ItemModel[]>({
		url: 'mms/items/inventory/unit-dosing/search-items',
		params: {
			SearchWord: searchWord,
		},
		immediate: false,
	});

	useEffect(() => {
		if (open == true) callApi();
	}, [open]);

	return (
		<BaseDialog title="Search Items" open={open} onClose={onClose} maxWidth="md">
			<div className="flex flex-grow flex-nowrap gap-3">
				<SearchTextField value={searchWord} onChange={(e) => setSearchWord(e.target.value)} />
				<SearchButton onClick={() => callApi()} />
			</div>

			<BaseTable
				className="mt-3"
				items={data}
				loading={loading}
				pagination={false}
				headers={[
					{ label: 'Item Description', value: 'Description' },
					{ label: 'Unit', value: 'BasedUnit' },
				]}
				rootHeight={500}
				actionColumn={(data) => (
					<CheckIconButton
						onClick={() => {
							setSelectedItem(data);
							onClose();
						}}
					/>
				)}
			/>
		</BaseDialog>
	);
};

export default UnitDosingSearchItemDialog;
