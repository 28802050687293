import BaseDialog from 'components/feedback/BaseDialog';
import ConfirmationDialog from 'components/feedback/ConfirmationDialog';
import BaseDatePicker from 'components/inputs/BaseDatePicker';
import BaseTextField from 'components/inputs/BaseTextField';
import SaveButton from 'components/inputs/buttons/SaveButton';
import useApi from 'hooks/useApi';
import { useState } from 'react';
import authStore from 'stores/auth-store';
import Swal from 'sweetalert2';
import ItemCategoryModel from 'types/item-category.model';
import SubDepartmentModel from 'types/sub-department.model';
import StockCardMain from '../../types/StockCardMain';

interface StockDispensingDialogProps {
	open: boolean;
	onClose: () => void;
	selectedItem: StockCardMain;
	item_category: ItemCategoryModel;
	sd: SubDepartmentModel;
	refresh: () => void;
}

const StockDispensingDialog = ({ open, onClose, selectedItem, item_category, sd, refresh }: StockDispensingDialogProps) => {
	const [qty, setQty] = useState<number>(0);
	const [remarks, setRemarks] = useState<string>('');
	const [openConf, setOpenConf] = useState<boolean>(false);
	const [saveData, setSaveData] = useState<{}>();

	const { callApi: stockDispensingApi } = useApi<{}>({
		url: 'mms/items/inventory/stock-adjustment-insert',
		method: 'post',
		login: true,
		onSuccess() {
			refresh();
			onClose();
		},
		immediate: false,
	});

	const save = () => {
		if (qty <= selectedItem.RemainingQty && qty > 0) {
			let dispensing = {
				ItemSubDeptID: sd.SubDeptID,
				LotNumber: selectedItem.LotNo,
				Barcode: selectedItem.Barcode,
				ItemID: selectedItem.ItemID,
				Unit: selectedItem.BasedUnit,
				SmallUnitPrice: selectedItem.BasedPrice,
				Qty: qty * -1,
				Remarks: remarks,
				ItemExpiry: selectedItem.ItemExpiry,
				isDeduct: true,
				isActive: true,
				isInventory: selectedItem.isInventory,
				isConsigned: selectedItem.isConsigned,
				SupplierID: selectedItem.SupplierID,
				SILineID: selectedItem.SILineID,
				hasEntry: false, // wai pa journals
				isDispensing: true,
				JournalID: 0, // default 0 lng dnay
			};

			setSaveData({
				adjustment: dispensing,
				item_category: item_category,
				EncodedBy: authStore?.user?.CompleteName ?? 'Stock Dispensing',
			});

			setOpenConf(true);
		} else
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Quantity must be greater than 0 and not greater than Remaining Qty',
				showConfirmButton: false,
				timer: 2500,
			});
	};

	const close = () => {
		setQty(0);
		setRemarks('');

		onClose();
	};

	return (
		<>
			<ConfirmationDialog open={openConf} onClose={() => setOpenConf(false)} onAgree={() => stockDispensingApi(saveData)} />
			<BaseDialog title="Stock Dispensing" open={open} onClose={close} maxWidth="sm">
				<div className="flex flex-grow flex-col gap-3">
					<BaseTextField label="Stock No" value={selectedItem.SILineID} readOnly />
					<BaseTextField label="Lot Number" value={selectedItem.LotNo} readOnly />
					<BaseTextField label="Barcode" value={selectedItem.Barcode} readOnly />
					<BaseTextField label="Item Description" value={selectedItem.Description} readOnly />

					<div className="flex flex-nowrap gap-3">
						<BaseTextField label="Remaining Qty" value={selectedItem.RemainingQty} readOnly />
						<BaseTextField label="Unit" value={selectedItem.BasedUnit} readOnly />
					</div>

					<BaseTextField label="Quantity" value={qty} onChange={(e) => setQty(Number(e.target.value))} type="number" />
					<BaseTextField label="Remarks" value={remarks} multiline minRows={3} onChange={(e) => setRemarks(e.target.value)} />
					<BaseDatePicker label="Item Expiry" value={selectedItem.ItemExpiry} readOnly />

					<SaveButton onClick={save}>DISPENSE - DEBIT TO EXPENSE</SaveButton>
				</div>
			</BaseDialog>
		</>
	);
};

export default StockDispensingDialog;
