import { SettingsBluetoothRounded } from '@mui/icons-material';
import BaseTable from 'components/data-display/BaseTable';
import AddButton from 'components/inputs/buttons/AddButton';
import PageContainer from 'components/layout/PageContainer';
import useApi from 'hooks/useApi';
import useBreadcrumb from 'hooks/useBreadbrumb';
import localforage from 'localforage';
import { useEffect, useState } from 'react';
import inventoryItemsStore from 'stores/inventory-items-store';
import ItemModel from 'types/item.model';
import StockIssuanceLineModel from 'types/MMS/stock-issuance-line.model';
import SubDepartmentModel from 'types/sub-department.model';
import { formatDateTime } from 'utils/helpers/date';
import BeginningBalanceDialog from './BeginningBalanceDialog';

const ViewBeginningBalanceDialog = () => {
	useBreadcrumb([{ label: 'Inventory Items', path: 'inventory-items' }, { label: 'Beginning Balance' }]);

	const [openBegBal, setOpenBegBal] = useState<boolean>(false);
	const [item, setItem] = useState<ItemModel>({} as ItemModel);
	const [sd, setSD] = useState<SubDepartmentModel>({} as SubDepartmentModel);

	const { callApi, data, loading } = useApi<StockIssuanceLineModel[]>({
		url: 'mms/items/inventory/beg-bal/view',
		params: {
			ItemID: item.ItemID,
			SubDeptID: sd.SubDeptID,
		},
		immediate: false,
	});

	const refresh = () => {
		callApi();
	};

	useEffect(() => {
		localforage.iterate(function (val, key, iteration) {
			// console.log(val, 'x'); // dala ang breadcrumbs sa localForage

			if (key == 'item') setItem(val as ItemModel);
			else if (key == 'subDept') setSD(val as SubDepartmentModel);
		});
	}, []);

	useEffect(() => {
		if (item.ItemID != undefined && sd.SubDeptID != undefined) callApi({ ItemID: item.ItemID, SubDeptID: sd.SubDeptID });
	}, [item, sd]);

	return (
		<PageContainer>
			<BeginningBalanceDialog open={openBegBal} onClose={() => setOpenBegBal(false)} refresh={refresh} />

			<div className="flex flex-col gap-3 mt-3">
				<div>
					<AddButton onClick={() => setOpenBegBal(true)}>Add Beginning Balance</AddButton>
				</div>
				<BaseTable
					items={data}
					loading={loading}
					headers={[
						{ label: 'Barcode', value: 'Barcode' },
						{ label: 'Unit', value: 'Unit' },
						{ label: 'Small Unit Price', value: 'SmallUnitPrice', type: 'currency' },
						{ label: 'Issue Quantity', value: 'IssueQty' },
						{ label: 'isActive', value: 'isActive', type: 'checkbox' },
						{ label: 'Item Expiry', value: (data) => formatDateTime(data.ItemExpiry) },
						{ label: 'Issuance Remarks', value: 'IssuanceRemarks' },
					]}
					rootHeight={700}
				/>
			</div>
		</PageContainer>
	);
};

export default ViewBeginningBalanceDialog;
