import React, { useEffect } from 'react';
import { BreadcrumbProps } from 'components/navigation/Breadcrumbs';
import BreadcrumbsStore from 'stores/breadcrumbs-store';

const useBreadcrumb = (props: BreadcrumbProps[], action?: React.ReactNode) => {
	useEffect(() => {
		BreadcrumbsStore.update(props);

		if (action) {
			BreadcrumbsStore.setActionComponent(action);
		} else {
			BreadcrumbsStore.setActionComponent(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [action]);
};

export default useBreadcrumb;
