import { makeAutoObservable } from 'mobx';
import { ADMISSION_VIEW_PATIENT_SIDEBAR_WIDTH, SIDEBAR_WIDTH, VIEW_PATIENT_SIDEBAR_WIDTH } from 'modules/his/constants/layout';

class LayoutStore {
	drawerWidth = SIDEBAR_WIDTH;
	sideBarState = true;
	viewPatientSideBarWidth = VIEW_PATIENT_SIDEBAR_WIDTH;
	viewPatientSideBarMini = false;
	admissionSideBarwidth = ADMISSION_VIEW_PATIENT_SIDEBAR_WIDTH;
	admissionSideBarState = false;

	constructor() {
		makeAutoObservable(this);
	}

	setDrawerWidth(width: number) {
		this.drawerWidth = width;
	}

	setSideBarState(state: boolean) {
		this.sideBarState = state;
	}

	setViewPatientSideBarWidth(width: number) {
		this.viewPatientSideBarWidth = width;
	}

	setViewPatientSideBarMini(state: boolean) {
		this.viewPatientSideBarMini = state;
	}

	setAdmissionSideBarState(state: boolean) {
		this.admissionSideBarState = state;
	}
}

export default new LayoutStore();
