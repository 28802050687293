import React from 'react';
import { HForm, HFormProps } from '@hybrain/mui.ui.final-form.h-form';
import SaveIcon from '@mui/icons-material/Save';
import isValid from 'date-fns/isValid';
import { convertToSqlDateTime } from 'utils/helpers/date';

type BaseFormProps = {
	autoParseDate?: boolean;
} & HFormProps;

const BaseForm = ({ submitButtonProps, submitButtonText, ...props }: BaseFormProps) => {
	return (
		<HForm
			submitButtonProps={{
				minWidth: '140px',
				startIcon: <SaveIcon />,
				...submitButtonProps,
				sx: {
					lineHeight: 1.2,
					...submitButtonProps?.sx,
				},
			}}
			autoParseDate
			submitButtonText={submitButtonText ?? 'Save'}
			{...props}
			// render={(formProps) => (children instanceof Function ? children(formProps) : children)}
		/>
	);
};

export default BaseForm;
