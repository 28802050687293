import { hUseApi, HUseApiProps } from '@hybrain/mui.hooks.use-api';
import api from 'api';
import React from 'react';

const useApi = <T extends any = any, R = any>(props: HUseApiProps<T, R>, deps?: React.DependencyList) =>
	hUseApi<T, R>(
		{
			api,
			...props,
		},
		deps
	);
export default useApi;
