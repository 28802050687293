import Grid from '@mui/material/Grid';

import BaseForm from 'components/form/BaseForm';
import BaseButton from 'components/inputs/buttons/BaseButton';
import ProceedButton from 'components/inputs/buttons/ProceedButton';
import GridFormTextField from 'components/form/grid/GridFormTextField';
import useLocalforage from 'hooks/useLocalforage';
import api from 'api';

import BaseDialog from './BaseDialog';

interface SecondaryLoginDialogProps<T = any> {
	open: boolean;
	onClose: () => void;
	onLoginSuccess: (userData?: T) => void;
	loading?: boolean;
}

const SecondaryLoginDialog = <T extends any = any>({ onLoginSuccess, loading, ...props }: SecondaryLoginDialogProps<T>) => {
	const userDetails = useLocalforage('user');

	const handleSubmit = async (values: any) => {
		const { data } = await api().post<any>('/users/transactional-login', values);
		if (!!data.user) {
			onLoginSuccess(data);
			props.onClose();
		}
	};

	return (
		<BaseDialog title="Please re-login" maxWidth="xs" {...props}>
			<BaseForm
				initialValues={{
					Username: userDetails?.UserAccess,
					Password: '',
				}}
				onSubmit={handleSubmit}
				buttonComponent={<ProceedButton loading={loading}>Login</ProceedButton>}
				noSubmitButton={false}
			>
				<Grid container spacing={1}>
					<GridFormTextField name="Username" label="Username" className="mt-3" required />
					<GridFormTextField name="Password" label="Password" type={'password'} autoComplete="Password" required />
					<Grid item xs={12} textAlign="center">
						<BaseButton
							loading={loading}
							onClick={() => {
								onLoginSuccess({} as any);
							}}
						>
							Fake Proceed
						</BaseButton>
					</Grid>
				</Grid>
			</BaseForm>
		</BaseDialog>
	);
};

export default SecondaryLoginDialog;
