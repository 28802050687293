import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const LeavesApprovalPage = lazy(() => import('modules/hris/pages/LeavesApproval/LeavesApprovalPage'));
const LeavesApprovalPendingPage = lazy(() => import('modules/hris/pages/LeavesApproval/pages/Pending/LeavesApprovalPendingPage'));
const LeavesApprovalVerifiedPage = lazy(() => import('modules/hris/pages/LeavesApproval/pages/Verified/LeavesApprovalVerifiedPage'));
const LeavesApprovalApprovedPage = lazy(() => import('modules/hris/pages/LeavesApproval/pages/Approved/LeavesApprovalApprovedPage'));
const LeavesApprovalRejectedPage = lazy(() => import('modules/hris/pages/LeavesApproval/pages/Rejected/LeavesApprovalRejectedPage'));
const LeavesApprovalCancelledPage = lazy(() => import('modules/hris/pages/LeavesApproval/pages/Cancelled/LeavesApprovalCancelledPage'));

const route: RouteObject[] = [
	{
		element: <LeavesApprovalPage />,
		children: [
			{ index: true, element: <LeavesApprovalPendingPage /> },
			{ path: 'verified', element: <LeavesApprovalVerifiedPage /> },
			{ path: 'approved', element: <LeavesApprovalApprovedPage /> },
			{ path: 'rejected', element: <LeavesApprovalRejectedPage /> },
			{ path: 'cancelled', element: <LeavesApprovalCancelledPage /> },
		],
	},
];

export default route;
