import MedicalRecordsModel from 'modules/his/pages/MedicalRecords/types/medical-records.model';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const MedicalRecordsPage = lazy(() => import('modules/his/pages/MedicalRecords/MedicalRecordsPage'));
const MedicalRecordsMainPage = lazy(() => import('modules/his/pages/MedicalRecords/pages/MedicalRecordsMainPage/MedicalRecordsMainPage'));

const medicalRecordsProps: MedicalRecordsModel = {
	module: 'Medical Records',
	path: 'medical-records',
};

const route: RouteObject[] = [
	{
		element: <MedicalRecordsPage {...medicalRecordsProps} />,
		children: [
			{
				index: true,
				element: <MedicalRecordsMainPage {...medicalRecordsProps} />,
			},
		],
	},
];

export default route;
