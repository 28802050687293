import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const DeliveryPage = lazy(() => import('modules/mms/pages/Delivery/DeliveryPage'));
const DeliveryPendingPOPage = lazy(() => import('modules/mms/pages/Delivery/pages/DeliveryPendingPO/DeliveryPendingPOPage'));
const DeliveryPendingDeliveryPage = lazy(
	() => import('modules/mms/pages/Delivery/pages/DeliveryPendingDelivery/DeliveryPendingDeliveryPage')
);
const DeliveryPostedDeliveryPage = lazy(() => import('modules/mms/pages/Delivery/pages/DeliveryPostedDelivery/DeliveryPostedDeliveryPage'));
const DeliveryVoidedDeliveryPage = lazy(() => import('modules/mms/pages/Delivery/pages/DeliveryVoidedDelivery/DeliveryVoidedDeliveryPage'));
const DeliveryDeliveryWithPaymentPage = lazy(
	() => import('modules/mms/pages/Delivery/pages/DeliveryDeliveryWithPayment/DeliveryDeliveryWithPaymentPage')
);

const route: RouteObject = {
	path: 'delivery',
	element: <DeliveryPage />,
	children: [
		{ index: true, element: <DeliveryPendingPOPage /> },
		{ path: 'pending', element: <DeliveryPendingDeliveryPage /> },
		{ path: 'posted', element: <DeliveryPostedDeliveryPage /> },
		{ path: 'voided', element: <DeliveryVoidedDeliveryPage /> },
		{ path: 'payment', element: <DeliveryDeliveryWithPaymentPage /> },
	],
};

export default route;
