import BaseTable from 'components/data-display/BaseTable';
import BaseCheckbox from 'components/inputs/BaseCheckbox';
import BaseDatePicker from 'components/inputs/BaseDatePicker';
import BaseButton from 'components/inputs/buttons/BaseButton';
import SearchButton from 'components/inputs/buttons/SearchButton';
import SearchTextField from 'components/inputs/text-fields/SearchTextField';
import PageContainer from 'components/layout/PageContainer';
import BaseTabs from 'components/navigation/BaseTabs';
import useBreadcrumb from 'hooks/useBreadbrumb';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import SettingsIcon from '@mui/icons-material/Settings';
import SoapIcon from '@mui/icons-material/Soap';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useEffect, useState } from 'react';
import StockCardMain from '../../types/StockCardMain';
import useApi from 'hooks/useApi';
import localforage from 'localforage';
import ItemModel from 'types/item.model';
import SubDepartmentModel from 'types/sub-department.model';
import { Tooltip } from '@mui/material';
import StockCardViewDialog from './StockCardViewDialog';
import { formatDate, formatDateTime } from 'utils/helpers/date';
import ItemStockAdjustmentDialog from './ItemStockAdjustmentDialog';
import ItemCategoryModel from 'types/item-category.model';
import UnitDosingDialog from './UnitDosingDialog';
import EditItemExpiryDialog from './EditItemExpiryDialog';
import Swal from 'sweetalert2';
import StockDispensingDialog from './StockDispensingDialog';

const StockCardPage = () => {
	useBreadcrumb([{ label: 'Inventory Items', path: 'inventory-items' }, { label: 'Stock Card' }]);

	const [filter, setFilter] = useState<string>('INVENTORY');
	const [openStockCard, setOpenStockCard] = useState<boolean>(false);
	const [openDispensing, setOpenDispensing] = useState<boolean>(false);
	const [openCurrent, setOpenCurrent] = useState<boolean>(false);
	const [openAdjustment, setOpenAdjustment] = useState<boolean>(false);
	const [openDosing, setOpenDosing] = useState<boolean>(false);
	const [openExpiry, setOpenExpiry] = useState<boolean>(false);

	const [dateFrom, setDateFrom] = useState<Date>(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
	const [dateTo, setDateTo] = useState<Date>(new Date());
	const [searchWord, setSearchWord] = useState<string>('');
	const [isConsignment, setIsConsignment] = useState<boolean>(true);

	const [item, setItem] = useState<ItemModel>({} as ItemModel);
	const [sd, setSD] = useState<SubDepartmentModel>({} as SubDepartmentModel);
	const [selectedItem, setSelectedItem] = useState<StockCardMain>({} as StockCardMain);

	const [totalRemaining, setTotalRemaining] = useState<number>(0);
	const [totalQty, setTotalQty] = useState<number>(0);

	const [summary, setSummary] = useState<{ Description: string; Total: number; HospitalTotal: number; ConsignmentTotal: number }[]>([]);

	const { callApi, data, loading } = useApi<StockCardMain[]>({
		url: 'mms/items/inventory/stock-card-summary-item',
		params: {
			ItemID: item.ItemID,
			SubDeptID: sd.SubDeptID,
			FilterType: filter,
			DateFrom: dateFrom,
			DateTo: dateTo,
			SearchWord: searchWord,
		},
		immediate: false,
	});

	const totalsMain = () => {
		let totalRemQty = 0;
		let totalQty = 0;

		data.forEach((item) => {
			totalRemQty += item.RemainingQty;
			totalQty += item.IssueQty;
		});

		setTotalRemaining(totalRemQty);
		setTotalQty(totalQty);
	};

	const totalsTable = () => {
		let total = 0;
		let hospTotal = 0;
		let consTotal = 0;

		let totalRemQty = 0;
		let hospRemQty = 0;
		let consRemQty = 0;

		if (data.length > 0) {
			data.forEach((item) => {
				total += item.IssueQty;
				totalRemQty += item.RemainingQty;

				if (item.TransactionType == 'CON') {
					consTotal += item.IssueQty;
					consRemQty += item.RemainingQty;
				} else {
					hospTotal += item.IssueQty;
					hospRemQty += item.RemainingQty;
				}
			});
		}

		let arr = [
			{
				Description: 'TOTAL QUANTITY',
				Total: total,
				HospitalTotal: hospTotal,
				ConsignmentTotal: consTotal,
			},
			{
				Description: 'USED QUANTITY',
				Total: total - totalRemQty,
				HospitalTotal: hospTotal - hospRemQty,
				ConsignmentTotal: consTotal - consRemQty,
			},
			{
				Description: 'REMAINING QUANTITY',
				Total: totalRemQty,
				HospitalTotal: hospRemQty,
				ConsignmentTotal: consRemQty,
			},
		];

		setSummary(arr);
	};

	useEffect(() => {
		localforage.iterate(function (val, key, iteration) {
			// console.log(val, 'x'); // dala ang breadcrumbs sa localForage

			if (key == 'item') setItem(val as ItemModel);
			else if (key == 'subDept') setSD(val as SubDepartmentModel);
		});
	}, []);

	useEffect(() => {
		if (item.ItemID != undefined && sd.SubDeptID != undefined) callApi();
	}, [item, sd]);

	useEffect(() => {
		totalsMain();
		totalsTable();
	}, [data, filter]);

	useEffect(() => {
		if (item.ItemID != undefined && sd.SubDeptID != undefined) callApi();
	}, [filter]);

	return (
		<>
			<StockCardViewDialog open={openStockCard} onClose={() => setOpenStockCard(false)} selectedItem={selectedItem} sdID={sd.SubDeptID} />
			<ItemStockAdjustmentDialog
				open={openAdjustment}
				onClose={() => setOpenAdjustment(false)}
				selectedItem={selectedItem}
				item_category={item.item_category ?? ({} as ItemCategoryModel)}
				sdID={sd.SubDeptID}
				refresh={callApi}
			/>
			<UnitDosingDialog
				open={openDosing}
				onClose={() => setOpenDosing(false)}
				selectedItem={selectedItem}
				item_category={item.item_category ?? ({} as ItemCategoryModel)}
			/>
			<EditItemExpiryDialog open={openExpiry} onClose={() => setOpenExpiry(false)} selectedItem={selectedItem} refresh={callApi} />
			<StockDispensingDialog
				open={openDispensing}
				onClose={() => setOpenDispensing(false)}
				selectedItem={selectedItem}
				item_category={item.item_category ?? ({} as ItemCategoryModel)}
				sd={sd}
				refresh={callApi}
			/>
			<PageContainer>
				<div className="flex gap-3 mt-3">
					<div className="flex flex-grow flex-row flex-nowrap gap-3">
						<BaseDatePicker label="Expiry Date From" value={dateFrom} onChange={(e) => setDateFrom(e)} />
						<BaseDatePicker label="Expiry Date To" value={dateTo} onChange={(e) => setDateTo(e)} />
						<SearchTextField label="Search Barcode / Lot Number" value={searchWord} onChange={(e) => setSearchWord(e.target.value)} />
						<BaseCheckbox
							label="Add Consignment"
							checked={isConsignment}
							onChange={() => (isConsignment == true ? setIsConsignment(false) : setIsConsignment(true))}
						/>
						<SearchButton onClick={() => callApi()} />
					</div>
				</div>

				<BaseTable
					items={data}
					pagination={false}
					loading={loading}
					headers={[
						{ label: 'Type', value: 'TransactionType' },
						{
							label: 'Category',
							value: (data) => (data.TransactionType == 'DON' ? 'DONATION' : data.TransactionType == 'CON' ? 'CONSIGNED' : 'OWNED'),
						},
						{ label: 'Unit Cost', value: 'SmallUnitPrice', type: 'currency' },
						{ label: 'Remaining Qty', value: (data) => data.RemainingQty ?? 0 },
						{ label: 'Item Expiry', value: (data) => formatDateTime(data.ItemExpiry) },
						{ label: 'HIS Current Stock', value: 'isHISCurrentStock', type: 'checkbox', align: 'center' },
						{ label: 'Lot Number', value: 'LotNo' },
						{ label: 'Barcode', value: 'Barcode' },
						{ label: 'Small Unit', value: 'BasedUnit' },
						{ label: 'Small Unit Qty', value: 'IssueQty' },
						{ label: 'Item Expiry', value: 'ItemExpiry' },
						{ label: 'Serviceable Duration', value: 'ServiceableDuration' },
						{
							label: 'Classification',
							value: (data) => (data.TransactionType == 'CON' ? 'CONSIGNMENT' : data.isInventory == true ? 'INVENTORY' : 'EXPENSE'),
						},
					]}
					rootHeight={500}
					actionColumn={(data) => (
						<div className="flex items-center gap-1">
							<Tooltip title="View Stock Card" disableTouchListener placement="top">
								<div>
									<BaseButton
										onClick={() => {
											setSelectedItem(data);
											setOpenStockCard(true);
										}}
									>
										<PlagiarismIcon />
									</BaseButton>
								</div>
							</Tooltip>

							<Tooltip title="Stock Adjustment" disableTouchListener placement="top">
								<div>
									<BaseButton
										onClick={() => {
											setSelectedItem(data);
											setOpenAdjustment(true);
										}}
									>
										<StackedBarChartIcon />
									</BaseButton>
								</div>
							</Tooltip>

							<Tooltip title="Set Current" disableTouchListener placement="top">
								<div>
									<BaseButton onClick={() => setOpenCurrent(true)} disabled>
										<SettingsIcon />
									</BaseButton>
								</div>
							</Tooltip>

							<Tooltip title="Dispensing" disableTouchListener placement="top">
								<div>
									<BaseButton
										onClick={() => {
											if (data.RemainingQty > 0) {
												setSelectedItem(data);
												setOpenDispensing(true);
											} else
												Swal.fire({
													icon: 'error',
													title: 'Error',
													text: 'Unable to dispense. Item has 0 remaining quantity',
													showConfirmButton: false,
													timer: 2500,
												});
										}}
									>
										<SoapIcon />
									</BaseButton>
								</div>
							</Tooltip>

							<Tooltip title="Unit Dosing" disableTouchListener placement="top">
								<div>
									<BaseButton
										onClick={() => {
											setSelectedItem(data);
											setOpenDosing(true);
										}}
										disabled={!data.isUnitDosing}
									>
										<FormatColorFillIcon />
									</BaseButton>
								</div>
							</Tooltip>

							<Tooltip title="Edit Item Expiry" disableTouchListener placement="top">
								<div>
									<BaseButton
										onClick={() => {
											setSelectedItem(data);
											setOpenExpiry(true);
										}}
									>
										<CalendarMonthIcon />
									</BaseButton>
								</div>
							</Tooltip>
						</div>
					)}
					headerActions={
						<BaseTabs
							defaultValue={'Inventory'}
							items={[
								{ label: 'Inventory', value: 'Inventory', onClick: () => setFilter('INVENTORY') },
								{ label: 'Expense', value: 'Expense', onClick: () => setFilter('EXPENSE') },
								{ label: 'Consignment', value: 'Consignment', onClick: () => setFilter('CONSIGNMENT') },
							]}
						/>
					}
					additionalRows={[
						{
							headers: [
								{ label: 'Summary', value: '', colSpan: 3 },
								{ label: '', value: 'RemainingQty' },
								{ label: '', value: '', colSpan: 4 },
								{ label: '', value: 'IssueQty' },
								{ label: '', value: '', colSpan: 3 },
							],
							items: [
								{
									RemainingQty: totalRemaining,
									IssueQty: totalQty,
								},
							],
						},
					]}
				/>

				<BaseTable
					className="mt-3"
					items={summary}
					loading={loading}
					pagination={false}
					headers={[
						{ label: '', value: 'Description', minWidth: 200 },
						{ label: 'SUMMARY OF OWNED AND CONSIGNMENT', value: 'Total', align: 'center' },
						{ label: 'HOSPITAL OWNED', value: 'HospitalTotal', align: 'center' },
						{ label: 'CONSIGNMENT ONLY', value: 'ConsignmentTotal', align: 'center' },
					]}
					rootHeight={200}
				/>
			</PageContainer>
		</>
	);
};

export default StockCardPage;
