import React from 'react';
import { observer } from 'mobx-react-lite';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { HToast } from '@hybrain/mui.ui.feedback.h-toast';
import { DialogStore } from '@hybrain/mui.hooks.use-api';
import { HResponseDialog } from '@hybrain/mui.ui.dialogs.h-response-dialog';

import './App.css';
import theme from './theme';
import Routes from './routes';
import ConfirmationDialog from 'components/feedback/ConfirmationDialog';
import confirmationDialogStore from 'stores/confirmation-dialog-store';
import SecondaryLoginDialog from 'components/feedback/SecondaryLoginDialog';
import { SecondaryLoginDialogStore } from '@hybrain/mui.hooks.use-api';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);

const ResponseDialogComponent = observer(() => (
	<>
		<HToast
			open={DialogStore.response.responseType === 'toast' ? DialogStore.response.status : false}
			onClose={() => DialogStore.close()}
			type={DialogStore.response.type}
			message={DialogStore.response.content}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
		/>
		<HResponseDialog
			open={DialogStore.response.responseType === 'dialog' ? DialogStore.response.status : false}
			onClose={() => DialogStore.close()}
			type={DialogStore.response.type}
			message={DialogStore.response.content}
		/>
	</>
));

const ConfirmationDialogComponent = observer(() => (
	<ConfirmationDialog
		open={confirmationDialogStore.open}
		onClose={() => confirmationDialogStore.onClose()}
		onAgree={() => {
			confirmationDialogStore.setLoading(true);
			confirmationDialogStore.onAgree(() => {
				confirmationDialogStore.setLoading(false);
			});
		}}
		onCancel={confirmationDialogStore.onCancel}
		loading={confirmationDialogStore.loading}
	/>
));

const SecondaryLoginComponent = observer(() => (
	<SecondaryLoginDialog
		open={SecondaryLoginDialogStore.open}
		onClose={() => SecondaryLoginDialogStore.setOpen(false)}
		loading={SecondaryLoginDialogStore.loading}
		onLoginSuccess={() => {
			SecondaryLoginDialogStore.setLoading(true);
			SecondaryLoginDialogStore.onLoginSuccess(() => {
				SecondaryLoginDialogStore.setLoading(false);
				SecondaryLoginDialogStore.setOpen(false);
			});
		}}
	/>
));

function App() {
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider
				theme={theme({
					palette: {
						primary: {
							dark: process.env.REACT_APP_PRIMARY_COLOR_DARK,
							main: process.env.REACT_APP_PRIMARY_COLOR_MAIN,
							light: process.env.REACT_APP_PRIMARY_COLOR_LIGHT,
						},
						secondary: {
							dark: process.env.REACT_APP_SECONDARY_COLOR_DARK,
							main: process.env.REACT_APP_SECONDARY_COLOR_MAIN,
							light: process.env.REACT_APP_SECONDARY_COLOR_LIGHT,
						},
					},
				})}
			>
				<ConfirmationDialogComponent />
				<ResponseDialogComponent />
				<SecondaryLoginComponent />
				<Routes />
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

export default App;
