import pathAccoutingTemplatesModel from 'modules/ams/pages/AccountingTemplate/types/path-accounting-templates';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const AccountingTemplatePage = lazy(() => import('modules/ams/pages/AccountingTemplate/AccountingTemplates'));
const AccountingTemplateMainPage = lazy(() => import('modules/ams/pages/AccountingTemplate/pages/AccountingTemplateMainPage'));
const  pathAccoutingTemplates: pathAccoutingTemplatesModel = {
	module: 'Accounting-Templates',
	path: 'accounting-templates',
};

const route: RouteObject[] = [
	{
		element: <AccountingTemplatePage {...pathAccoutingTemplates} />,
		children: [
			{
				index: true,
				element: <AccountingTemplateMainPage {...pathAccoutingTemplates} />,
			},
		],
	},
];

export default route;
