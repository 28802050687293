import { makeAutoObservable } from 'mobx';
import UserModel from 'types/user.model';

class AuthStore {
	user: UserModel = {} as UserModel;

	constructor() {
		makeAutoObservable(this);
	}

	setUser(payload: UserModel) {
		this.user = payload;
	}

	// setaccessToken(payload: string) {
	// 	this.user.accessToken = payload;
	// }
}

export default new AuthStore();
