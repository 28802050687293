import RenalCareModel from 'modules/his/pages/RenalCare/types/renal-care.model';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const RenalCarePage = lazy(() => import('modules/his/pages/RenalCare/RenalCarePage'));
const RenalCarePageMainPage = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/RenalCareMainPage')
);
const RenalCareViewPatientPageMainPage = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/RenalCareViewPatientMainPage')
);
const RenalCareViewPatientPage = lazy(
	() => import('modules/his/pages/RenalCare/RenalCareViewPatientPage')
);

const StandingOrdersPage = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/RenalCareStandingOrderPage')
);
const SpecialOrderPage = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/RenalCareSpecialOrders')
);
const HemodialysisEOR = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/RenalCareHemodialysisEndorsementOrdersReport')
);
const HemodialysisOrderForm = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/RenalCareHemodialysisOrderForm')
);
const HemodialysisPP = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/RenalCareHemodialysisPatientProfile')
);
const HemodialysBTR = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/RenalCareHemodialysisBloodTransfusionRecord')
);
const HemodialysisRecord = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/RenalCareHemodialysisRecord')
);
const HemodialysisRecordReports = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/RenalCareHemodialysisRecordReport')
);
const ImmunizationRFP = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/ImmunizationRFP')
);
const Pneumococcal = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/Pneumococcal')
);
const InfluenzaVaccine = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/InfluenzaVaccine')
);
const OthersImmunizationRecords = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/OthersImmunizationRecords')
);
const ImmunizationsRecordsReport = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/ImmunizationsRecordsReport')
);
const NutritionalAssessmentTool = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/NutritionalAssessmentTool')
);
const NutritionalAssessmentHistory = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/NutritionalAssessmentHistory')
);
const SummaryOfMedicines = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/SummaryOfMedicines')
);

const PrescriptionVerification = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/HemodialysisOrderFormPages/PrescriptionVerificationPage')
);

const SafetyCheck = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/HemodialysisOrderFormPages/SafetyCheckPage')
);

const SpecialOrder = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/HemodialysisOrderFormPages/SpecialOrderPage')
);

const Predialysis = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/HemodialysisOrderFormPages/PreDialysisPage')
);
const Postdialysis = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/HemodialysisOrderFormPages/PostDialysisPage')
);

const HomeInstruction = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/HemodialysisOrderFormPages/HomeInstructionsPage')
);

const AssessmentPredialysis = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/HemodialysisOrderFormPages/AssessmentPredialysisPage')
);

const AssessmentPostdialysis = lazy(
	() => import('modules/his/pages/RenalCare/pages/RenalCareMainPage/HemodialysisOrderFormPages/AssessmentPostdialysisPage')
);

const RenalCareProps: RenalCareModel = {
	module: 'Renal Care',
	path: 'renal-care',
};

const route: RouteObject[] = [
	{
		element: <RenalCarePage {...RenalCareProps} />,
		children: [
			{
				index: true,
				element: <RenalCarePageMainPage {...RenalCareProps} />,
			},


		],
	},
	{
		path: 'patient/view',
		element: <RenalCareViewPatientPage {...RenalCareProps} />,
		children: [
			{
				path: 'dashboard',
				element: <RenalCareViewPatientPageMainPage {...RenalCareProps} />,
			},
			{
				path: 'standing-orders',
				element: <StandingOrdersPage {...RenalCareProps} />,
			},
			{
				path: 'special-orders',
				element: <SpecialOrderPage {...RenalCareProps} />,
			},
			{
				path: 'hemodialysis-endorsement-orders-report',
				element: <HemodialysisEOR {...RenalCareProps} />,
			},
			{
				path: 'hemodialysis-order-form',
				element: <HemodialysisOrderForm {...RenalCareProps} />,
			},
			{
				path: 'hemodialysis-patient-profile',
				element: <HemodialysisPP {...RenalCareProps} />,
			},
			{
				path: 'hemodialysis-blood-transfusion-record',
				element: <HemodialysBTR {...RenalCareProps} />,
			},
			{
				path: 'hemodialysis-records',
				element: <HemodialysisRecord {...RenalCareProps}
				/>,
				children: [
					{
						path: 'prescription-verification',
						element: <PrescriptionVerification {...RenalCareProps} />,
					},
					{
						path: 'safety-check',
						element: <SafetyCheck {...RenalCareProps} />,
					},
					{
						path: 'special-orders',
						element: <SpecialOrder {...RenalCareProps} />,
					},
					{
						path: 'predialysis',
						element: <Predialysis {...RenalCareProps} />,
					},
					{
						path: 'postdialysis',
						element: <Postdialysis {...RenalCareProps} />,
					},
					{
						path: 'home-instructions',
						element: <HomeInstruction {...RenalCareProps} />,
					},
					{
						path: 'assessment-predialysis',
						element: <AssessmentPredialysis {...RenalCareProps} />,
					},
					{
						path: 'assessment-postdialysis',
						element: <AssessmentPostdialysis {...RenalCareProps} />,
					},
				],
			},
			{
				path: 'hemodialysis-records-report',
				element: <HemodialysisRecordReports {...RenalCareProps} />,
			},
			{
				path: 'immunization-records-for-patient',
				element: <ImmunizationRFP {...RenalCareProps} />,
			},
			{
				path: 'pneumococcal',
				element: <Pneumococcal {...RenalCareProps} />,
			},
			{
				path: 'influenza-vaccine',
				element: <InfluenzaVaccine {...RenalCareProps} />,
			},
			{
				path: 'others',
				element: <OthersImmunizationRecords {...RenalCareProps} />,
			},
			{
				path: 'immunization-records-report',
				element: <ImmunizationsRecordsReport {...RenalCareProps} />,
			},
			{
				path: 'nutritional-assessment-tool',
				element: <NutritionalAssessmentTool {...RenalCareProps} />,
			},
			{
				path: 'nutritional-assessment-history',
				element: <NutritionalAssessmentHistory {...RenalCareProps} />,
			},
			{
				path: 'summary-of-medicines',
				element: <SummaryOfMedicines {...RenalCareProps} />,
			},
		],
	},
];

export default route;
