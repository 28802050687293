import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const PhilhealthAllocationItemsAndServicesPage = lazy(
	() =>
		import(
			'modules/his/pages/ViewPatient/pages/AccountSummary/pages/PhilhealthAllocation/pages/PhilhealthAllocationItemsAndServices/PhilhealthAllocationItemsAndServicesPage'
		)
);

const PhilhealthAllocationRoomPage = lazy(
	() =>
		import(
			'modules/his/pages/ViewPatient/pages/AccountSummary/pages/PhilhealthAllocation/pages/PhilhealthAllocationRoom/PhilhealthAllocationRoomPage'
		)
);

const PhilhealthAllocationDoctorPage = lazy(
	() =>
		import(
			'modules/his/pages/ViewPatient/pages/AccountSummary/pages/PhilhealthAllocation/pages/PhilhealthAllocationDoctor/PhilhealthAllocationDoctorPage'
		)
);

const PhilhealthAllocationAdjustmentPage = lazy(
	() =>
		import(
			'modules/his/pages/ViewPatient/pages/AccountSummary/pages/PhilhealthAllocation/pages/PhilhealthAllocationAdjustment/PhilhealthAllocationAdjustmentPage'
		)
);

const routes: RouteObject[] = [
	{ index: true, element: <PhilhealthAllocationItemsAndServicesPage /> },
	{ path: 'room', element: <PhilhealthAllocationRoomPage /> },
	{ path: 'doctor', element: <PhilhealthAllocationDoctorPage /> },
	{ path: 'adjustment', element: <PhilhealthAllocationAdjustmentPage /> },
];

export default routes;
