import localforage from 'localforage';
import { useEffect, useState } from 'react';

const useLocalforage = <T = any>(attribute: string) => {
	const [data, setData] = useState<T>([] as any);

	useEffect(() => {
		(async () => {
			const value = (await localforage.getItem(attribute)) as T;
			setData(value);
		})();
	}, [attribute]);

	return data;
};

export default useLocalforage;
