import React from 'react';
import BaseButton, { BaseButtonProps } from '../BaseButton';
import CheckIcon from '@mui/icons-material/Check';

const CheckIconButton = (props: BaseButtonProps) => {
	return (
		<BaseButton icon {...props}>
			<CheckIcon />
		</BaseButton>
	);
};

export default CheckIconButton;
