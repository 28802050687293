export function isJSON(str: any) {
	try {
		return JSON.parse(str) && !!str;
	} catch (e) {
		return false;
	}
}

export function parseJSON(str: any) {
	return isJSON(str) ? JSON.parse(str) : null;
}

export function JSONtoXML(obj: any) {
	let xml = '';
	for (var prop in obj) {
		xml += obj[prop] instanceof Array ? '' : '<' + prop + '>';
		if (obj[prop] instanceof Array) {
			for (var array in obj[prop]) {
				xml += '<' + prop + '>';
				xml += JSONtoXML({ ...obj[prop][array] });
				xml += '</' + prop + '>';
			}
		} else if (typeof obj[prop] == 'object') {
			xml += JSONtoXML({ ...obj[prop] });
		} else {
			xml += obj[prop];
		}
		xml += obj[prop] instanceof Array ? '' : '</' + prop + '>';
	}
	xml = xml.replace(/<\/?[0-9]{1,}>/g, '');
	return xml;
}
