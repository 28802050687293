import React, { Suspense, useEffect, useMemo, useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';

interface TabItem<T> {
	label: string;
	path?: T;
	onClick?: () => void;
	value?: T;
	component?: React.ReactNode;
}

type BaseTabsProps<T> = {
	items: TabItem<T>[];
	defaultValue?: T;
	content?: React.ReactNode;
	children?: (tab: T) => React.ReactNode;
} & Omit<TabsProps, 'defaultValue' | 'children'>;

const BaseTabs = <T extends string | number = string | number>({
	items,
	defaultValue,
	className,
	content,
	children,
	...props
}: BaseTabsProps<T>) => {
	const [tab, setTab] = useState<any>(defaultValue ?? '');
	const navigate = useNavigate();

	const handleClick = (item: TabItem<T>) => {
		if (item.path) {
			navigate(item.path + '');
		}

		if (item.onClick) {
			item.onClick();
		}
	};

	useEffect(() => {
		if (defaultValue) {
			setTab(defaultValue);
		}
	}, [defaultValue]);

	const selectedItem = useMemo(() => {
		return items.find((item, index) => tab === (item.value ?? item.path ?? index));
	}, [tab, items]);

	return (
		<>
			<Card className={className} elevation={3} sx={{ display: 'flex', gap: 1 }}>
				<Tabs value={tab} onChange={(e, value) => setTab(value)} {...props}>
					{items?.map((item, index) => (
						<Tab
							label={item.label}
							onClick={() => handleClick(item)}
							value={item.value ?? item.path ?? index}
							key={index}
							sx={{
								color: 'black',
								textTransform: 'none',
							}}
						/>
					))}
				</Tabs>

				<div className="flex gap-2 flex-grow justify-end">{content}</div>
			</Card>

			<Suspense fallback={<></>}>
				{selectedItem?.component && <div className="mt-2 flex flex-col flex-grow">{selectedItem?.component}</div>}
			</Suspense>

			{children?.(tab)}
		</>
	);
};

export default BaseTabs;
