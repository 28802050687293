import pathManualBillsModel from 'modules/ams/pages/ManualBills/types/path-manual-bills';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const PettyCashCustodianListPage = lazy(() => import('modules/ams/pages/PettyCashCustodianList/PettyCashCustodianListPage'));
const PettyCashCustodianListMainPage = lazy(() => import('modules/ams/pages/PettyCashCustodianList/pages/PettyCashCustodianListMainPage'));

const route: RouteObject[] = [
	{
		element: <PettyCashCustodianListPage />,
		children: [
			{
				index: true,
				element: <PettyCashCustodianListMainPage />,
			},
		],
	},
];

export default route;
