import { hApi, HApiProps } from '@hybrain/mui.hooks.use-api';
import { DialogStore } from 'stores/dialog-store';

const api = (props?: HApiProps) => {
	return hApi({
		baseURL: process.env.REACT_APP_API_URL,
		...props,
		DialogStore,
		fakeToken: process.env.REACT_APP_FAKE_TOKEN,
	});
};

export default api;
