import React from 'react';
import { HSelect, HSelectProps } from '@hybrain/mui.ui.inputs.h-select';

interface BaseSelectProps {}

const BaseSelect = <T extends any>(props: BaseSelectProps & HSelectProps<T>) => {
	return <HSelect size="small" {...props} />;
};

export default BaseSelect;
