import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import purchaseRequestsRoutes from './mms-patient-requests-routes';
import purchaseRequestStatusRoutes from './mms-patient-requests-status-routes';
import purchaseRequestApprovalLevel1Routes from './mms-patient-request-approval-level-1-routes';
import purchaseRequestApprovalLevel2Routes from './mms-patient-request-approval-level-2-routes';
import stockRequestRoutes from './mms-stock-request-routes';
import purchaseOrderRoutes from './mms-purchase-order-routes';
import purchaseOrderStatusRoutes from './mms-purchase-order-status-routes';
import purchaseOrderApprovalLevel1Routes from './mms-purchase-order-approval-level-1-routes';
import purchaseOrderApprovalLevel2Routes from './mms-purchase-order-approval-level-2-routes';
import deliveryRoutes from './mms-delivery-routes';
import donationsRoutes from './mms-donations-routes';
import stockIssuanceRoutes from './mms-stock-issuance-routes';
import consignmentsRoutes from './mms-consignments-routes';
import itemDisposalRoutes from './mms-item-disposal-routes';
import inventoryItemtsRoutes from './mms-inventory-items-routes';
import suppliersRoutes from './mms-suppliers-routes';
import returnToSupplierRoutes from './mms-return-to-supplier-routes';
import receivingOfReplacementsRoutes from './mms-receiving-of-replacements-routes';
import itemsAndServicesRoutes from './mms-items-and-services-routes';

const MMSLayout = lazy(() => import('modules/mms/layout/MMSLayout'));

const MMSDashboardPage = lazy(() => import('modules/mms/pages/Dashboard/MMSDashboardPage'));

const StockIssuanceStatusPage = lazy(() => import('modules/mms/pages/StockIssuanceStatus/StockIssuanceStatusPage'));
const ItemRepackIssuancePage = lazy(() => import('modules/mms/pages/ItemRepackIssuance/ItemRepackIssuancePage'));
const MaterialProductionPage = lazy(() => import('modules/mms/pages/MaterialProduction/MaterialProductionPage'));
// const InventoryItemsPage = lazy(() => import('modules/mms/pages/InventoryItems/InventoryItemsPage'));
const ReportsListingPage = lazy(() => import('modules/mms/pages/ReportsListing/ReportsListingPage'));
const MaintenancePage = lazy(() => import('modules/mms/pages/Maintenance/MaintenancePage'));

const route: RouteObject = {
	path: 'mms',
	element: <MMSLayout />,
	children: [
		{ index: true, element: <MMSDashboardPage /> },
		purchaseRequestsRoutes,
		purchaseRequestStatusRoutes,
		purchaseRequestApprovalLevel1Routes,
		purchaseRequestApprovalLevel2Routes,
		stockRequestRoutes,
		purchaseOrderRoutes,
		purchaseOrderStatusRoutes,
		purchaseOrderApprovalLevel1Routes,
		purchaseOrderApprovalLevel2Routes,
		deliveryRoutes,
		donationsRoutes,
		stockIssuanceRoutes,
		{
			path: 'stock-issuance-status',
			element: <StockIssuanceStatusPage />,
		},
		{
			path: 'item-repack-issuance',
			element: <ItemRepackIssuancePage />,
		},
		{
			path: 'material-production',
			element: <MaterialProductionPage />,
		},
		// {
		// 	path: 'inventory-items',
		// 	element: <InventoryItems />,
		// },
		inventoryItemtsRoutes,
		consignmentsRoutes,
		{
			path: 'reports-listing',
			element: <ReportsListingPage />,
		},
		{ path: 'maintenance', element: <MaintenancePage /> },
		itemDisposalRoutes,
		suppliersRoutes,

		returnToSupplierRoutes,
		receivingOfReplacementsRoutes,
		{
			path: 'items',
			children: itemsAndServicesRoutes,
		},
	],
};

export default route;
