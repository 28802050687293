import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import ViewPatientModel from 'modules/his/pages/ViewPatient/types/view-patient.model';

import accountSummaryPaymentAllocationRoutes from './admission-account-summary-payment-allocation-routes';
import accountSummaryHMOAllocationRoutes from './admission-account-summary-hmo-allocation-routes';
import accountSummaryPhilhealthAllocationRoutes from './admission-account-summary-philhealth-allocation-routes';
import accountSummaryPatientPromissoryRoutes from './admission-account-summary-patient-promissory-routes';
import accountSummaryDiscountRoutes from './admission-account-summary-discount-routes';

const AccountSummaryDashBoardPage = lazy(
	() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/AccountSummaryDashboard/AccountSummaryDashboardPage')
);
const AlterTransactionPage = lazy(
	() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/AlterTransaction/AlterTransactionPage')
);
const HospitalChargesPage = lazy(
	() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/HospitalCharges/HospitalChargesPage')
);
const RoomChargesPage = lazy(() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/RoomCharges/RoomChargesPage'));
const DailyChargesPage = lazy(() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/DailyCharges/DailyChargesPage'));
const DoctorChargesPage = lazy(() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/DoctorCharges/DoctorChargesPage'));
const RoomAdjustmentPage = lazy(() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/RoomAdjustment/RoomAdjustmentPage'));
const DiscountPage = lazy(() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/Discount/DiscountPage'));
const HMOAllocationPage = lazy(() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/HMOAllocation/HMOAllocationPage'));
const PhilhealthAllocationPage = lazy(
	() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/PhilhealthAllocation/PhilhealthAllocationPage')
);
const PaymentAllocationPage = lazy(
	() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/PaymentAllocation/PaymentAllocationPage')
);
const PatientPaymentsPage = lazy(
	() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/PatientPayments/PatientPaymentsPage')
);
const PatientStatusPage = lazy(() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/PatientStatus/PatientStatusPage'));
const PatientPromissoryPage = lazy(
	() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/PatientPromissory/PatientPromissoryPage')
);
const RefundPage = lazy(() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/Refund/RefundPage'));
const AccountSummaryAccountSummaryPage = lazy(
	() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/AccountSummaryAccountSummary/AccountSummaryAccountSummaryPage')
);
const AccountHistoryPage = lazy(() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/AccountHistory/AccountHistoryPage'));
const StatementOfAccountPage = lazy(
	() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/StatementOfAccount/StatementOfAccountPage')
);
const RemarksPage = lazy(() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/Remarks/RemarksPage'));

const admissionProps: ViewPatientModel = {
	module: 'Admission',
	path: 'admission',
};

const routes: RouteObject[] = [
	{ index: true, element: <AccountSummaryDashBoardPage {...admissionProps} /> },
	{ path: 'alter-transaction', element: <AlterTransactionPage {...admissionProps} /> },
	{ path: 'hospital-charges', element: <HospitalChargesPage {...admissionProps} /> },
	{ path: 'room-charges', element: <RoomChargesPage {...admissionProps} /> },
	{ path: 'daily-charges', element: <DailyChargesPage {...admissionProps} /> },
	{ path: 'doctor-charges', element: <DoctorChargesPage {...admissionProps} /> },
	{ path: 'room-adjustment', element: <RoomAdjustmentPage {...admissionProps} /> },
	{ path: 'discount', element: <DiscountPage {...admissionProps} />, children: accountSummaryDiscountRoutes },
	{ path: 'hmo-allocation', element: <HMOAllocationPage {...admissionProps} />, children: accountSummaryHMOAllocationRoutes },
	{
		path: 'philhealth-allocation',
		element: <PhilhealthAllocationPage {...admissionProps} />,
		children: accountSummaryPhilhealthAllocationRoutes,
	},
	{ path: 'payment-allocation', element: <PaymentAllocationPage {...admissionProps} />, children: accountSummaryPaymentAllocationRoutes },
	{ path: 'patient-payments', element: <PatientPaymentsPage {...admissionProps} /> },
	{ path: 'patient-status', element: <PatientStatusPage {...admissionProps} /> },
	{ path: 'patient-promissory', element: <PatientPromissoryPage {...admissionProps} />, children: accountSummaryPatientPromissoryRoutes },
	{ path: 'refund', element: <RefundPage {...admissionProps} /> },
	{ path: 'account-summary', element: <AccountSummaryAccountSummaryPage {...admissionProps} /> },
	{ path: 'account-history', element: <AccountHistoryPage {...admissionProps} /> },
	{ path: 'statement-of-account', element: <StatementOfAccountPage {...admissionProps} /> },
	{ path: 'remarks', element: <RemarksPage {...admissionProps} /> },
];

export default routes;
