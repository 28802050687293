import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import employeeRequestsRoutes from './hris-employee-requests-routes';
import leavesApprovalRoutes from './hris-leaves-approval-routes';
import employeeRoutes from './hris-employees-routes';
import employeeProfileRoutes from './hris-employee-profile-routes';
import employeeDashboardRoutes from './hris-employee-dashboard-routes';
import humanResourceRoutes from './hris-human-resource-routes';

const HRISLayout = lazy(() => import('modules/hris/layout/HRISLayout'));
const HRISDashboardPage = lazy(() => import('modules/hris/pages/Dashboard/HRISDashboardPage'));
const MaintenancePage = lazy(() => import('modules/hris/pages/Maintenance/MaintenancePage'));
const AnnouncementPage = lazy(() => import('modules/hris/pages/Announcements/AnnouncementPage'));
const AttendancePage = lazy(() => import('modules/hris/pages/Attendance/AttendancePage'));
const UserPage = lazy(() => import('modules/hris/pages/Users/UserPage'));
const EventsPage = lazy(() => import('modules/hris/pages/Events/EventsMainPage'));

const route: RouteObject = {
	path: '/hris',
	element: <HRISLayout />,
	children: [
		{ index: true, element: <HRISDashboardPage /> },
		{ path: 'human-resource', children: humanResourceRoutes },
		{ path: 'employee-requests', children: employeeRequestsRoutes },
		{ path: 'leaves-approval', children: leavesApprovalRoutes },
		{ path: 'dashboard', children: employeeDashboardRoutes },
		{ path: 'employees', children: employeeRoutes },
		{ path: 'employee-profile', children: employeeProfileRoutes },
		{ path: 'maintenance', element: <MaintenancePage /> },
		{ path: 'announcements', element: <AnnouncementPage /> },
		{ path: 'events', element: <EventsPage /> },
		{ path: 'attendance', element: <AttendancePage /> },
		{ path: 'users', element: <UserPage /> },
	],
};

export default route;
