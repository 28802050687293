import { makeAutoObservable, toJS } from 'mobx';
import { BreadcrumbProps } from '../components/navigation/Breadcrumbs';
import localforage from 'localforage';

class BreadcrumbsStore {
	items: BreadcrumbProps[] = [];
	action: React.ReactNode = '';

	constructor() {
		makeAutoObservable(this);
	}

	update(items: BreadcrumbProps[]) {
		this.items = items;
		localforage.setItem('breadcrumbs', items);
	}

	change(index: number) {
		let filteredItems = [];
		for (let i = 0; i < this.items.length; i++) {
			if (i <= index) {
				filteredItems.push(toJS(this.items[i]));
			}
		}

		this.items = filteredItems;
		localforage.setItem('breadcrumbs', filteredItems);
	}

	setActionComponent(action: React.ReactNode) {
		this.action = action;
	}
}

export default new BreadcrumbsStore();
