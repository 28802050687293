import React from 'react';
import Grid, { GridProps, GridSize } from '@mui/material/Grid';
import FormTextField from '../FormTextField';
import { HFormTextFieldProps } from '@hybrain/mui.ui.final-form.h-form-text-field';

type GridFormTextFieldProps = {
	gridProps?: GridProps;
	xl?: boolean | GridSize | undefined;
	lg?: boolean | GridSize | undefined;
	md?: boolean | GridSize | undefined;
	xs?: boolean | GridSize | undefined;
} & HFormTextFieldProps;

const GridFormTextField = ({ className, gridProps, xl, lg, md, xs, ...props }: GridFormTextFieldProps) => {
	return (
		<Grid item xs={xs ?? 12} md={md} lg={lg} xl={xl} className={className} {...gridProps}>
			<FormTextField {...props} />
		</Grid>
	);
};

export default GridFormTextField;
