import pathManualBillsModel from 'modules/ams/pages/ManualBills/types/path-manual-bills';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const BankSeriesPage = lazy(() => import('modules/ams/pages/BankSeries/BankSeriesPage'));
const BankSeriesMainPage = lazy(() => import('modules/ams/pages/BankSeries/pages/BankSeriesMainPage'));

const route: RouteObject[] = [
	{
		element: <BankSeriesPage />,
		children: [
			{
				index: true,
				element: <BankSeriesMainPage />,
			},
		],
	},
];

export default route;
