import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { HTextField, HTextFieldProps } from '@hybrain/mui.ui.inputs.h-text-field';

const SearchTextField = (props: HTextFieldProps) => {
	return (
		<HTextField
			size="small"
			borderRadius={100}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon />
					</InputAdornment>
				),
			}}
			{...props}
		/>
	);
};

export default SearchTextField;
