import BaseTable from 'components/data-display/BaseTable';
import useApiPatientReg from 'hooks/useApiPatientReg';
import useBreadcrumbViewPatientReg from 'modules/his/hooks/useBreadcrumbViewPatientReg';
import { useState } from 'react';
import PatientRegPatientAdjustmentModel from 'types/patient-reg-patient-adjustment.model';
import { formatDate } from 'utils/helpers/date';
import { formatCurrency } from 'utils/helpers/numbers';
import ViewPatientModel from '../../types/view-patient.model';
import AddPatientAdjustmentDialog from './component/AddPatientAdjustmentDialog';

const PatientAdjustmentPage = (props: ViewPatientModel) => {
	useBreadcrumbViewPatientReg({
		breadcrumbs: [{ label: 'Patient Adjustment' }],
		...props,
	});

	const [amount, setAmount] = useState(0);

	const {
		data: adjustments,
		loading: dashboardLoading,
		callApi: callAdjustmentsApi,
	} = useApiPatientReg<PatientRegPatientAdjustmentModel[]>({
		url: 'his/patient-registry/adjustment/list',
		onSuccess({ data }) {
			let totalAmount = 0;

			data?.response.forEach((item) => {
				totalAmount += item.Amount;
			});
			setAmount(totalAmount);
		},
	});

	const refresh = () => {
		callAdjustmentsApi();
	};

	return (
		<BaseTable
			items={adjustments}
			loading={dashboardLoading}
			headers={[
				{ label: 'Description', value: 'Description' },
				{ label: 'Adjustment Type', value: 'AdjustmentType' },
				{ label: 'Amount', value: (data) => formatCurrency(data.Amount), type: 'currency' },
				{ label: 'Created By', value: 'CreatedBy' },
				{ label: 'Created Date', value: (data) => formatDate(data.CreatedDate) },
				{ label: 'Account Template', value: (data) => data.account_template?.Name },
			]}
			additionalRows={[
				{
					headers: [
						{ label: '', value: '', colSpan: 2 },
						{ label: 'Total Amount', value: 'Amount', type: 'currency' },
						{ label: '', value: '', colSpan: 3 },
					],
					items: [
						{
							Amount: amount,
						},
					],
				},
			]}
			headerActions={<AddPatientAdjustmentDialog refresh={refresh} />}
		/>
	);
};

export default PatientAdjustmentPage;
