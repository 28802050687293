import React from 'react';
import Grid, { GridProps, GridSize } from '@mui/material/Grid';
import { HFormRadioGroupProps } from '@hybrain/mui.ui.final-form.h-form-radio-group';
import FormRadioGroup from '../FormRadioGroup';

interface GridFormRadioGroupProps {
	gridProps?: GridProps;
	xl?: boolean | GridSize | undefined;
	lg?: boolean | GridSize | undefined;
	md?: boolean | GridSize | undefined;
	xs?: boolean | GridSize | undefined;
}

const GridFormRadioGroup = <T extends any>({
	className,
	gridProps,
	xl,
	lg,
	md,
	xs,
	...props
}: GridFormRadioGroupProps & HFormRadioGroupProps<T>) => {
	return (
		<Grid item xs={xs ?? 12} md={md} lg={lg} xl={xl} className={className} {...gridProps}>
			<FormRadioGroup {...props} />
		</Grid>
	);
};

export default GridFormRadioGroup;
