import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const EmployeeRequestsPage = lazy(() => import('modules/hris/pages/EmployeeRequests/EmployeeRequestsPage'));
const EmployeeRequestsPendingRequestsPage = lazy(
	() => import('modules/hris/pages/EmployeeRequests/pages/PendingRequests/EmployeeRequestsPendingRequestsPage')
);
const EmployeeRequestsApprovedRequestsPage = lazy(
	() => import('modules/hris/pages/EmployeeRequests/pages/ApprovedRequests/EmployeeRequestsApprovedRequestsPage')
);
const EmployeeRequestsRejectedRequestsPage = lazy(
	() => import('modules/hris/pages/EmployeeRequests/pages/RejectedRequests/EmployeeRequestsRejectedRequestsPage')
);
const EmployeeRequestsOthersPage = lazy(() => import('modules/hris/pages/EmployeeRequests/pages/Others/EmployeeRequestsOthersPage'));

const route: RouteObject[] = [
	{
		element: <EmployeeRequestsPage />,
		children: [
			{ index: true, element: <EmployeeRequestsPendingRequestsPage /> },
			{ path: 'approved', element: <EmployeeRequestsApprovedRequestsPage /> },
			{ path: 'rejected', element: <EmployeeRequestsRejectedRequestsPage /> },
			{ path: 'others', element: <EmployeeRequestsOthersPage /> },
		],
	},
];

export default route;
