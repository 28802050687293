import { unstable_toUnitless } from '@mui/material';
import BaseDialog from 'components/feedback/BaseDialog';
import ConfirmationDialog from 'components/feedback/ConfirmationDialog';
import BaseCheckbox from 'components/inputs/BaseCheckbox';
import BaseDatePicker from 'components/inputs/BaseDatePicker';
import BaseSelect from 'components/inputs/BaseSelect';
import BaseTextField from 'components/inputs/BaseTextField';
import SaveButton from 'components/inputs/buttons/SaveButton';
import useApi from 'hooks/useApi';
import localforage from 'localforage';
import { observer } from 'mobx-react-lite';
import AccountsModel from 'modules/his/pages/ItemsAndServices/types/accounts-model';
import { useEffect, useState } from 'react';
import authStore from 'stores/auth-store';
import inventoryItemsStore from 'stores/inventory-items-store';
import Swal from 'sweetalert2';
import ItemUnitModel from 'types/item-unit.model';
import ItemModel from 'types/item.model';
import SupplierModel from 'types/MMS/supplier.model';
import SubDepartmentModel from 'types/sub-department.model';
import AccountsDialog from '../AccountsDialog';

interface BeginningBalanceProps {
	open: boolean;
	onClose: () => void;
	// selectedItem: ItemModel;
	refresh: () => void;
}

const BeginningBalanceDialog = ({ open, onClose, refresh }: BeginningBalanceProps) => {
	const [openAccDialog, setOpenAccDialog] = useState<boolean>(false);
	const [openConfDialog, setOpenConfDialog] = useState<boolean>(false);
	const [selectedAccount, setSelectedAccount] = useState<AccountsModel>({} as AccountsModel);
	const [startDate, setStartDate] = useState<Date>(new Date());
	const [unit, setUnit] = useState<string>('');
	const [unitPrice, setUnitPrice] = useState<number>(0);
	const [vatAmnt, setVatAmnt] = useState<number>(0);
	const [qty, setQty] = useState<number>(0);
	const [lotNo, setLotNo] = useState<string>('');
	const [barcode, setBarcode] = useState<string>('');
	const [itemExpiry, setItemExpiry] = useState<Date>(new Date());
	const [supplierID, setSupplierID] = useState<number>();
	const [remarks, setRemarks] = useState<string>('');
	const [isInventory, setIsInventory] = useState<boolean>(true);

	const [saveWithEntries, setSaveWithEntries] = useState<boolean>(false);
	const [saveData, setSaveData] = useState<{}>();

	const [item, setItem] = useState<ItemModel>({} as ItemModel);
	const [sd, setSD] = useState<SubDepartmentModel>({} as SubDepartmentModel);

	const {
		callApi: suppliersApi,
		data: suppliers,
		loading: supplierLoading,
	} = useApi<SupplierModel[]>({
		url: 'mms/maintenance/suppliers/active',
		onSuccess({ data }) {
			setSupplierID(data?.response?.[0]?.SupplierID ?? 0);
		},
		immediate: false,
	});

	const {
		callApi: unitsApi,
		data: units,
		loading: unitLoading,
	} = useApi<ItemUnitModel[]>({
		url: 'mms/items/item_unit/',
		params: {
			ItemID: item.ItemID,
		},
		onSuccess({ data }) {
			setUnit(data?.response?.[0]?.BigUnit ?? '');
		},
		immediate: false,
	});

	const { callApi: insertBegBalNoEntriesApi } = useApi<{}>({
		url: '/mms/items/inventory/beg-bal/insert-no-entries',
		method: 'post',
		login: true,
		onSuccess() {
			refresh();
			clearFields();
			onClose();
		},
		immediate: false,
	});

	const handleSubmit = (data: any) => {
		if (data.LotNo == '' || data.Barcode == '') {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Lot Number / Barcode must not be blank',
				showConfirmButton: false,
				timer: 2500,
			});

			return;
		}

		if (selectedAccount.AccountID == 0 && saveWithEntries == true) {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Please select an account',
				showConfirmButton: false,
				timer: 2500,
			});

			return;
		}

		let saveForSI = {
			IssuanceDate: startDate,
			SourceSubDeptID: sd.SubDeptID,
			TargetSubDeptID: sd.SubDeptID,
			IssuanceRemarks: remarks,
			isActive: true,
			isDirect: false,
			isReturn: false,
			isPost: true,
			hasEntry: false,
			CreatedBy: authStore?.user?.CompleteName ?? 'Beginning Balance',
		};

		let saveForSILine = {
			LotNo: lotNo,
			Barcode: barcode,
			OrderLineID: 0,
			ItemID: item.ItemID,
			Unit: unit,
			IssueQty: qty * (unit == units[0].SmallUnit ? 1 : units[0].SmallQty),
			UnitQty: qty * (unit == units[0].SmallUnit ? 1 : units[0].SmallQty),
			isActive: true,
			TransactionType: 'BB',
			SmallUnitPrice: unitPrice,
			isInventory: isInventory,
			ItemExpiry: itemExpiry,
			isConsigned: false,
			SupplierID: supplierID,
			SILineIDMain: 0,
			FundSourceID: 1,
			CreatedBy: authStore?.user?.CompleteName ?? 'Beginning Balance',
		};

		setSaveData({
			data: saveForSI,
			line: saveForSILine,
		});

		setOpenConfDialog(true);
	};

	const clearFields = () => {
		setSelectedAccount({} as AccountsModel);
		setStartDate(new Date());
		setUnit('');
		setUnitPrice(0);
		setVatAmnt(0);
		setQty(0);
		setLotNo('');
		setBarcode('');
		setItemExpiry(new Date());
		setSupplierID(undefined);
		setRemarks('');
		setIsInventory(true);
	};

	useEffect(() => {
		if (open == true) {
			localforage.iterate(function (val, key, iteration) {
				if (key == 'item') setItem(val as ItemModel);
				else if (key == 'subDept') setSD(val as SubDepartmentModel);
			});
		}
	}, [open]);

	useEffect(() => {
		if (item.ItemID != undefined) {
			setUnitPrice(item.BasedPrice);
			setVatAmnt(item.BasedPrice / 1.12);
			suppliersApi();
			unitsApi();
		}
	}, [item, sd]);

	return (
		<BaseDialog
			title="Beginning Balance"
			open={open}
			onClose={() => {
				clearFields();
				onClose();
			}}
			maxWidth="sm"
		>
			<ConfirmationDialog
				open={openConfDialog}
				onClose={() => setOpenConfDialog(false)}
				onAgree={() => insertBegBalNoEntriesApi(saveData)}
			/>
			<AccountsDialog open={openAccDialog} onClose={() => setOpenAccDialog(false)} setAccount={setSelectedAccount} />
			<div className="flex flex-grow flex-nowrap grid grid-col-1 gap-2">
				<BaseDatePicker label="Start Date" value={startDate} onChange={(e) => setStartDate(e)} />
				<BaseTextField label="Item Category" value={item.item_category?.Description ?? ''} />
				<BaseTextField label="Item Description" value={item.Description ?? ''} />
				<BaseSelect
					label="Unit"
					value={unit ?? ''}
					options={units}
					loading={unitLoading}
					optionText="BigUnit"
					optionValue="BigUnit"
					onChange={(e) => setUnit(e.target.value)}
				/>
				<div className="flex no-wrap gap-2">
					<BaseTextField
						name="UnitPrice"
						label="Unit Price"
						type="number"
						value={unitPrice}
						onChange={(e) => {
							setUnitPrice(Number(e.target.value));
							setVatAmnt(Number(e.target.value) / 1.12);
						}}
					/>
					<BaseTextField
						name="VatAmount"
						label="Excluded Vat Amount"
						type="number"
						value={vatAmnt}
						// onChange={(e) => setVatAmnt(unitPrice / 1.12)}
						readOnly
					/>
				</div>
				<BaseTextField label="Quantity" type="number" value={qty} onChange={(e) => setQty(Number(e.target.value))} />
				<BaseTextField label="Lot Number" value={lotNo} onChange={(e) => setLotNo(e.target.value)} />
				<BaseTextField label="Barcode" value={barcode} onChange={(e) => setBarcode(e.target.value)} />
				<BaseDatePicker label="Item Expiry" value={itemExpiry} onChange={(e) => setStartDate(e)} />
				<BaseTextField label="Remarks" multiline minRows={3} value={remarks} onChange={(e) => setRemarks(e.target.value)} />
				<BaseSelect
					name="Supplier"
					label="Supplier"
					value={supplierID ?? ''}
					options={suppliers}
					loading={supplierLoading}
					optionText="Name"
					optionValue="SupplierID"
					onChange={(e) => setSupplierID(Number(e.target.value))}
				/>
				<BaseTextField label="Account" value={selectedAccount.Name ?? ''} onDoubleClick={() => setOpenAccDialog(true)} readOnly />
				<BaseCheckbox
					name="isInventory"
					label="is Inventory: "
					checked={isInventory}
					onChange={() => (isInventory == true ? setIsInventory(false) : setIsInventory(true))}
				/>
				<div className="flex flex-grow place-self-end gap-3">
					<SaveButton
						onClick={(data) => {
							setSaveWithEntries(false);
							handleSubmit(data);
						}}
						size="large"
					>
						Save w/o Journal Entries
					</SaveButton>
					<SaveButton
						onClick={(data) => {
							setSaveWithEntries(true);
							handleSubmit(data);
						}}
						size="large"
					>
						Save w/ Journal Entries
					</SaveButton>
				</div>
			</div>
		</BaseDialog>
	);
};

export default observer(BeginningBalanceDialog);
