import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const PurchaseRequestApprovalLevel2Page = lazy(
	() => import('modules/mms/pages/PurchaseRequestApprovalLevel2/PurchaseRequestApprovalLevel2Page')
);

const PurchaseRequestApprovalPendingPage = lazy(
	() => import('modules/mms/pages/PurchaseRequestApprovalLevel2/pages/PurchaseRequestApprovalPending/PurchaseRequestApprovalPendingPage')
);

const PurchaseRequestApprovalApprovedPage = lazy(
	() => import('modules/mms/pages/PurchaseRequestApprovalLevel2/pages/PurchaseRequestApprovalApproved/PurchaseRequestApprovalApprovedPage')
);

const PurchaseRequestApprovalRejectedPage = lazy(
	() => import('modules/mms/pages/PurchaseRequestApprovalLevel2/pages/PurchaseRequestApprovalRejected/PurchaseRequestApprovalRejectedPage')
);

const route: RouteObject = {
	path: 'purchase-request-approval-level-2',
	element: <PurchaseRequestApprovalLevel2Page />,
	children: [
		{ index: true, element: <PurchaseRequestApprovalPendingPage /> },
		{ path: 'approved', element: <PurchaseRequestApprovalApprovedPage /> },
		{ path: 'rejected', element: <PurchaseRequestApprovalRejectedPage /> },
	],
};

export default route;
