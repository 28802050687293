import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const StockRequestPage = lazy(() => import('modules/mms/pages/StockRequest/StockRequestPage'));
const StockRequesPendingPage = lazy(() => import('modules/mms/pages/StockRequest/pages/StockRequestPending/StockRequestPendingPage'));
const StockRequestPostedPage = lazy(() => import('modules/mms/pages/StockRequest/pages/StockRequestPosted/StockRequestPostedPage'));
const StockRequestVoidedPage = lazy(() => import('modules/mms/pages/StockRequest/pages/StockRequestVoided/StockRequestVoidedPage'));

const route: RouteObject = {
	path: 'stock-request',
	element: <StockRequestPage />,
	children: [
		{ index: true, element: <StockRequesPendingPage /> },
		{ path: 'posted', element: <StockRequestPostedPage /> },
		{ path: 'voided', element: <StockRequestVoidedPage /> },
	],
};

export default route;
