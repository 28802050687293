import ItemsAndServicesModel from 'modules/his/pages/ItemsAndServices/types/items-and-services.model';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const ItemAndServicesPage = lazy(() => import('modules/his/pages/ItemsAndServices/ItemAndServicesPage'));
const ItemsAndServicesMainPage = lazy(
	() => import('modules/his/pages/ItemsAndServices/pages/ItemsAndServicesMainPage/ItemsAndServicesMainPage')
);

const itemsAndServicesProps: ItemsAndServicesModel = {
	module: 'Items and Services',
	path: 'items-and-services',
};

const route: RouteObject[] = [
	{
		element: <ItemAndServicesPage {...itemsAndServicesProps} />,
		children: [
			{
				index: true,
				element: <ItemsAndServicesMainPage {...itemsAndServicesProps} />,
			},
		],
	},
];

export default route;
