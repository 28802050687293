import pathManualBillsModel from 'modules/ams/pages/ManualBills/types/path-manual-bills';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const ReportsPage = lazy(() => import('modules/ams/pages/Reports/ReportsPage'));
const BalanceSheetPage = lazy(() => import('modules/ams/pages/Reports/BalanceSheetPage'));
const TrialBalancePage = lazy(() => import('modules/ams/pages/Reports/TrialBalancePage'));
const IncomeStatementPage = lazy(() => import('modules/ams/pages/Reports/IncomeStatementPage'));
const GeneralLedgerPage = lazy(() => import('modules/ams/pages/Reports/GeneralLedgerPage'));

const route: RouteObject[] = [
	{
		element: <ReportsPage />,
		children: [
			{
				path: 'balance-sheet',
				element: <BalanceSheetPage />,
			},
			{
				path: 'trial-balance',
				element: <TrialBalancePage />,
			},
			{
				path: 'income-statement',
				element: <IncomeStatementPage />,
			},
			{
				path: 'general-ledger',
				element: <GeneralLedgerPage />,
			},
		],
	},
];

export default route;
