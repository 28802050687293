import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const ChartOfAccountsPage = lazy(() => import('modules/ams/pages/ChartOfAccounts/ChartOfAccountsPage'));
const ChartOfAccountsMainPage = lazy(() => import('modules/ams/pages/ChartOfAccounts/pages/ChartOfAccountsMainPage'));

const route: RouteObject[] = [
	{
		element: <ChartOfAccountsPage />,
		children: [
			{
				index: true,
				element: <ChartOfAccountsMainPage />,
			},
		],
	},
];

export default route;
