import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const ConsignmentsPage = lazy(() => import('modules/mms/pages/Consignments/ConsignmentsPage'));
const ConsignmentsPendingPage = lazy(() => import('modules/mms/pages/Consignments/pages/ConsignmentsPending/ConsignmentsPendingPage'));
const ConsignmentsPostedPage = lazy(() => import('modules/mms/pages/Consignments/pages/ConsignmentsPosted/ConsignmentsPostedPage'));
const ConsignmentsVoidedPage = lazy(() => import('modules/mms/pages/Consignments/pages/ConsignmentsVoided/ConsignmentsVoidedPage'));
const ConsignmentsWithBillingPage = lazy(
	() => import('modules/mms/pages/Consignments/pages/ConsignmentsWithBilling/ConsignmentsWithBillingPage')
);

const route: RouteObject = {
	path: 'consignments',
	element: <ConsignmentsPage />,
	children: [
		{ index: true, element: <ConsignmentsPendingPage /> },
		{ path: 'posted', element: <ConsignmentsPostedPage /> },
		{ path: 'voided', element: <ConsignmentsVoidedPage /> },
		{ path: 'with-billing', element: <ConsignmentsWithBillingPage /> },
	],
};

export default route;
