import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const BasicInformationPage = lazy(() => import('modules/hris/pages/Employees/pages/BasicInformationPage'));
const EducationAttainmentPage = lazy(() => import('modules/hris/pages/Employees/pages/EducationalAttainmentPage'));
const FamilyDependentsPage = lazy(() => import('modules/hris/pages/Employees/pages/FamilyDependentsPage'));
const LicenseInformationPage = lazy(() => import('modules/hris/pages/Employees/pages/LicenseInformationPage'));
const DocumentsPage = lazy(() => import('modules/hris/pages/Employees/pages/DocumentsPage'));
const TrainingCertificatePage = lazy(() => import('modules/hris/pages/Employees/pages/TrainingCertificatePage'));
const WorkExperiencePage = lazy(() => import('modules/hris/pages/Employees/pages/WorkExperiencePage'));
const SalarySettingsPage = lazy(() => import('modules/hris/pages/Employees/pages/SalarySettingsPage'));
const EmploymentPage = lazy(() => import('modules/hris/pages/Employees/pages/EmploymentPage'));
const WorkAssignmentPage = lazy(() => import('modules/hris/pages/Employees/pages/WorkAssignmentPage'));
const ShiftPage = lazy(() => import('modules/hris/pages/Employees/pages/ShiftPage'));
const LeaveSettingsPage = lazy(() => import('modules/hris/pages/Employees/pages/LeaveSettingsPage'));
const LeaveRecordsPage = lazy(() => import('modules/hris/pages/Employees/pages/LeaveRecordsPage'));
const EmployeeProfilePage = lazy(() => import('modules/hris/pages/EmployeeProfile/EmployeeProfilePage'));

const route: RouteObject[] = [
	{
		element: <EmployeeProfilePage />,
		children: [
			{
				index: true,
				element: <BasicInformationPage />,
			},
			{
				path: 'educational-attainment',
				element: <EducationAttainmentPage />,
			},
			{
				path: 'family-dependents',
				element: <FamilyDependentsPage />,
			},
			{
				path: 'employment',
				element: <EmploymentPage />,
			},
			{
				path: 'work-assignment',
				element: <WorkAssignmentPage />,
			},
			{
				path: 'salary-settings',
				element: <SalarySettingsPage />,
			},
			{
				path: 'shifting',
				element: <ShiftPage />,
			},
			{
				path: 'leave-settings',
				element: <LeaveSettingsPage />,
			},
			{
				path: 'leave-records',
				element: <LeaveRecordsPage />,
			},
			{
				path: 'license-information',
				element: <LicenseInformationPage />,
			},
			{
				path: 'documents',
				element: <DocumentsPage />,
			},
			{
				path: 'training-certificate',
				element: <TrainingCertificatePage />,
			},
			{
				path: 'work-experience',
				element: <WorkExperiencePage />,
			},
		],
	},
];
export default route;
