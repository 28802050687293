import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { autorun } from 'mobx';

import { BreadcrumbProps } from 'components/navigation/Breadcrumbs';
import BreadcrumbsStore from 'stores/breadcrumbs-store';
import { joinStrings } from 'utils/helpers/string';

import ViewPatientModel from '../pages/ViewPatient/types/view-patient.model';
import patientStore from 'stores/patient-store';

type UseBreadcrumbViewPatientRegProps = {
	breadcrumbs: BreadcrumbProps[];
} & ViewPatientModel;

const useBreadcrumbViewPatientReg = ({ path, breadcrumbs, module }: UseBreadcrumbViewPatientRegProps) => {
	const navigate = useNavigate();

	useEffect(() => {
		autorun(() => {
			if (!patientStore.patientReg) {
				navigate('/his/' + path);
				return;
			}

			// const patient = patientStore.patientReg?.patient;

			if (Object.keys(patientStore.patientReg?.patient || {}).length === 0) {
				return;
			}

			BreadcrumbsStore.update([
				{ label: module, path: `/his/${path}` },
				{ label: 'Patient', path: `/his/${path}/patient/view` },
				{
					label: joinStrings([
						patientStore.patientReg?.patient?.FirstName,
						patientStore.patientReg?.patient?.MiddleName,
						patientStore.patientReg?.patient?.LastName,
					]),
					path: `/his/${path}/patient/view`,
				},
				...breadcrumbs,
			]);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [patientStore.patientReg]);
};

export default useBreadcrumbViewPatientReg;
