import { makeAutoObservable } from 'mobx';
import DeepPartial from 'types/deep-partial';
import PatientRegModel from 'types/patient-reg.model';

class PatientStore {
	patientReg: PatientRegModel = {} as PatientRegModel;
	loading: boolean = false;
	refreshData: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	setPatientReg(payload: DeepPartial<PatientRegModel>) {
		// console.log(this.patientReg);
		// Object.keys(payload).forEach((key) => {
		// 	(this.patientReg as any)[key] = (payload as any)[key];
		// });
		sessionStorage.setItem('patientReg', JSON.stringify(payload));
		this.patientReg = payload as any;
	}

	setLoading(loading: boolean) {
		this.loading = loading;
	}

	refresh() {
		this.refreshData = !this.refreshData;
	}
}

export default new PatientStore();
