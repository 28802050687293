import BaseTable from 'components/data-display/BaseTable';
import BaseDialog from 'components/feedback/BaseDialog';
import BaseTextField from 'components/inputs/BaseTextField';
import useApi from 'hooks/useApi';
import { useEffect, useState } from 'react';
import { formatDateTime } from 'utils/helpers/date';
import StockCardMain from '../../types/StockCardMain';
import StockCardView from '../../types/StockCardView';

interface StockCardViewDialogProps {
	open: boolean;
	onClose: () => void;
	selectedItem: StockCardMain;
	sdID: number;
}

const StockCardViewDialog = ({ open, onClose, selectedItem, sdID }: StockCardViewDialogProps) => {
	const [details, setDetails] = useState<StockCardView[]>();

	const { callApi, data, loading } = useApi<{ stock_card_detailed: [] }[]>({
		url: 'mms/items/inventory/stock-card-per-siline',
		params: {
			SILineID: selectedItem.SILineID,
			SubDeptID: sdID,
		},
		onSuccess({ data }) {
			let arr: any = [];
			data?.response?.[0]?.stock_card_detailed?.forEach((item) => {
				arr.push(item);
			});

			setDetails(arr);
		},
		immediate: false,
	});

	const stockCardType = (type: string): string => {
		if (type == 'BB') return 'BEGINNING BALANCE';
		else if (type == 'IR' || type == 'MR') return 'REPACK ISSUANCE';
		else if (type == 'ROR') return 'REPLACEMENT RECEIVING';
		else if (type == 'CON') return 'CONSIGNMENT';
		else if (type == 'DON') return 'DONATION';
		else if (type == 'RTS') return 'RETURN TO SUPPLIER';
		else if (type == 'RTW') return 'RETURN TO WAREHOUSE';
		else if (type == 'UD') return 'UNIT DOSING';
		else if (type == 'DR' || type == 'SR') return 'STOCK ISSUANCE';
		else return type;
	};

	useEffect(() => {
		if (open == true) callApi();
	}, [open]);

	return (
		<BaseDialog title="View Stock Card" open={open} onClose={onClose} maxWidth={'lg'}>
			<div className="flex flex-row flex-nowrap gap-3">
				{selectedItem.SILineID}
				<BaseTextField label="Lot Number" value={selectedItem.LotNo} readOnly />
				<BaseTextField label="Barcode" value={selectedItem.Barcode} readOnly />
				<BaseTextField label="Expiry Date" value={selectedItem.ItemExpiry} readOnly />
				<BaseTextField label="Small Unit Price" value={selectedItem.SmallUnitPrice} readOnly />
			</div>

			<BaseTable
				className="mt-3"
				items={details ?? []}
				pagination={false}
				loading={loading}
				headers={[
					{ label: 'Status', value: (data) => stockCardType(data.stockcardtype) },
					{ label: 'Sub Department', value: 'subdeptname' },
					{ label: 'Contact Name', value: 'contact' },
					{ label: 'Transaction No', value: 'transno' },
					{ label: 'Small Unit', value: 'basedunit' },
					{ label: 'Total Qty (Small Unit)', value: 'qty' },
					{ label: 'Issue Unit', value: 'issueunit' },
					{ label: 'Issue Qty', value: 'qty' },
					{ label: 'Balance', value: 'running_balance' },
					{ label: 'Transaction Date and Time', value: (data) => formatDateTime(data.transactdatetime) },
					{ label: 'Transact By', value: 'transactby' },
				]}
				rootHeight={600}
			/>
		</BaseDialog>
	);
};

export default StockCardViewDialog;
