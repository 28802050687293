import React from 'react';
import BaseButton, { BaseButtonProps } from './BaseButton';
import PlusThickIcon from 'mdi-material-ui/PlusThick';

const AddButton = ({ children, ...props }: BaseButtonProps) => {
	return (
		<BaseButton {...props}>
			<PlusThickIcon sx={{ mr: 1 }} />
			{children || 'Add'}
		</BaseButton>
	);
};

export default AddButton;
