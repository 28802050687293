import ViewPatientModel from 'modules/his/pages/ViewPatient/types/view-patient.model';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const DiscountSummaryPage = lazy(
	() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/Discount/pages/DiscountSummary/DiscountSummaryPage')
);

const DiscountDetailedSummaryPage = lazy(
	() =>
		import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/Discount/pages/DiscountDetailedSummary/DiscountDetailedSummaryPage')
);

const DiscountAllocationPage = lazy(
	() => import('modules/his/pages/ViewPatient/pages/AccountSummary/pages/Discount/pages/DiscountAllocation/DiscountAllocationPage')
);

const DiscountAllocationHospitalChargesPage = lazy(
	() =>
		import(
			'modules/his/pages/ViewPatient/pages/AccountSummary/pages/Discount/pages/DiscountAllocation/pages/DiscountAllocationHospitalChargesPage'
		)
);

const DiscountAllocationRoomChargesPage = lazy(
	() =>
		import(
			'modules/his/pages/ViewPatient/pages/AccountSummary/pages/Discount/pages/DiscountAllocation/pages/DiscountAllocationRoomChargesPage'
		)
);

const admissionProps: ViewPatientModel = {
	module: 'Admission',
	path: 'admission',
};

const routes: RouteObject[] = [
	{ index: true, element: <DiscountSummaryPage /> },
	{ path: 'detailed-summary', element: <DiscountDetailedSummaryPage /> },
	{
		path: 'allocation',
		element: <DiscountAllocationPage {...admissionProps} />,
		children: [
			{ index: true, element: <DiscountAllocationHospitalChargesPage /> },
			{ path: 'room-charges', element: <DiscountAllocationRoomChargesPage /> },
		],
	},
];

export default routes;
