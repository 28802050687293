import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import ViewPatientModel from 'modules/his/pages/ViewPatient/types/view-patient.model';
import accountSummaryRoutes from './admission/admission-account-summary-routes';
import PatientAdjustmentPage from 'modules/his/pages/ViewPatient/pages/PatientAdjustment/PatientAdjustmentPage';

const AdmissionPage = lazy(() => import('modules/his/pages/Admission/AdmissionPage'));
const ViewPatientPage = lazy(() => import('modules/his/pages/ViewPatient/ViewPatientPage'));
const PatientEdit = lazy(() => import('modules/his/pages/ViewPatient/pages/EditPatient/PatientEdit'));
const PatientRegistration = lazy(() => import('modules/his/pages/Admission/pages/PatientRegistration/PatientRegistration'));

const ViewPatientDefaultPage = lazy(() => import('modules/his/pages/ViewPatient/pages/ViewPatientDefault/ViewPatientDefaultPage'));
const DashboardPage = lazy(() => import('modules/his/pages/ViewPatient/pages/Dashboard/DashboardPage'));
const RequisitionsPage = lazy(() => import('modules/his/pages/ViewPatient/pages/Requistions/RequisitionsPage'));
const DietPlanPage = lazy(() => import('modules/his/pages/ViewPatient/pages/DietPlan/DietPlanPage'));
const AccountSummaryPage = lazy(() => import('modules/his/pages/ViewPatient/pages/AccountSummary/AccountSummaryPage'));
// const AdmitPatientPage = lazy(() => import('modules/his/pages/ViewPatient/pages/AdmitPatient/AdmitPatientDialog'));
// const BabyStatusPage = lazy(() => import('modules/his/pages/ViewPatient/pages/BabyStatus/BabyStatusDialog'));
// const CancelAdmissionDialog = lazy(() => import('modules/his/pages/ViewPatient/pages/CancelAdmission/CancelAdmissionDialog'));
const ClaimFormPage = lazy(() => import('modules/his/pages/ViewPatient/pages/ClaimForm/ClaimFormPage'));
const CreditNotePage = lazy(() => import('modules/his/pages/ViewPatient/pages/CreditNote/CreditNotePage'));
const DiagnosisPage = lazy(() => import('modules/his/pages/ViewPatient/pages/Diagnosis/DiagnosisPage'));
const DietHistoryPage = lazy(() => import('modules/his/pages/ViewPatient/pages/DietHistory/DietHistoryPage'));
// const DischargePage = lazy(() => import('modules/his/pages/ViewPatient/pages/Discharge/DischargeDialog'));
// const DispositionPage = lazy(() => import('modules/his/pages/ViewPatient/pages/Disposition/DispositionDialog'));
const DoctorsPhysiciansPage = lazy(() => import('modules/his/pages/ViewPatient/pages/DoctorsPhysicians/DoctorsPhysiciansPage'));
const DoctorsPhysiciansFeesPage = lazy(() => import('modules/his/pages/ViewPatient/pages/DoctorsPhysiciansFees/DoctorsPhysiciansFeesPage'));
const DoctorsPhysiciansRolesPage = lazy(
	() => import('modules/his/pages/ViewPatient/pages/DoctorsPhysiciansRoles/DoctorsPhysiciansRolesPage')
);
const DrugResistancePage = lazy(() => import('modules/his/pages/ViewPatient/pages/DrugResistance/DrugResistancePage'));
const ExaminationsProceduresPage = lazy(
	() => import('modules/his/pages/ViewPatient/pages/ExaminationsProcedures/ExaminationsProceduresPage')
);
// const ERTimeLeftPage = lazy(() => import('modules/his/pages/ViewPatient/pages/ERTimeLeft/ERTimeLeftPage'));
const FinancialAssistanceGuarantorPage = lazy(
	() => import('modules/his/pages/ViewPatient/pages/FinancialAssistanceGuarantor/FinancialAssistanceGuarantorPage')
);
const ForBillingPage = lazy(() => import('modules/his/pages/ViewPatient/pages/ForBilling/ForBillingPage'));
const ForDischargePage = lazy(() => import('modules/his/pages/ViewPatient/pages/ForDischarge/ForDischargePage'));
const HMOMedicalPackagesPage = lazy(() => import('modules/his/pages/ViewPatient/pages/HMOMedicalPackages/HMOMedicalPackagesPage'));
const InstructionPage = lazy(() => import('modules/his/pages/ViewPatient/pages/Instruction/InstructionPage'));
const MaternityFormPage = lazy(() => import('modules/his/pages/ViewPatient/pages/MaternityForm/MaternityFormPage'));
const MedicalPackagesPage = lazy(() => import('modules/his/pages/ViewPatient/pages/MedicalPackages/MedicalPackagesPage'));
const NewRequisitionPage = lazy(() => import('modules/his/pages/ViewPatient/pages/NewRequisition/NewRequisitionPage'));
const PatientOperationsPage = lazy(() => import('modules/his/pages/ViewPatient/pages/PatientOperations/PatientOperationsPage'));
const PostChargesPage = lazy(() => import('modules/his/pages/ViewPatient/pages/PostCharges/PostChargesPage'));
const PostDietPage = lazy(() => import('modules/his/pages/ViewPatient/pages/PostDiet/PostDietPage'));
const ReferToOtherClinicPage = lazy(() => import('modules/his/pages/ViewPatient/pages/ReferToOtherClinic/ReferToOtherClinicPage'));
// const RelocatePage = lazy(() => import('modules/his/pages/ViewPatient/pages/Relocate/RelocateDialog'));
const ReportsPage = lazy(() => import('modules/his/pages/ViewPatient/pages/Reports/ReportsPage'));
const RequisitionListPage = lazy(() => import('modules/his/pages/ViewPatient/pages/RequisitionList/RequisitionListPage'));
// const RevertPatientStatusPage = lazy(() => import('modules/his/pages/ViewPatient/pages/RevertPatientStatus/RevertPatientStatusDialog'));
const RoomsPage = lazy(() => import('modules/his/pages/ViewPatient/pages/Rooms/RoomsPage'));
// const ServiceTypePage = lazy(() => import('modules/his/pages/ViewPatient/pages/ServiceType/ServiceTypeDialog'));
// const TagUntagForClearanceDialog = lazy(
// 	() => import('modules/his/pages/ViewPatient/pages/TagUntagForClearance/TagUntagForClearanceDialog')
// );
const TransferToERPage = lazy(() => import('modules/his/pages/ViewPatient/pages/TransferToER/TransferToERPage'));
const TransferToOPDPage = lazy(() => import('modules/his/pages/ViewPatient/pages/TransferToOPD/TransferToOPDPage'));
const VitalSignsPage = lazy(() => import('modules/his/pages/ViewPatient/pages/VitalSigns/VitalSignsPage'));
const ViewRequisitionPage = lazy(() => import('modules/his/pages/ViewPatient/pages/Requistions/components/ViewRequisitionPage'));
const EditRequisitionPage = lazy(() => import('modules/his/pages/ViewPatient/pages/Requistions/components/EditRequisitionPage'));

const admissionProps: ViewPatientModel = {
	module: 'Admission',
	path: 'admission',
};

const route: RouteObject[] = [
	{
		index: true,
		element: <AdmissionPage />,
	},
	{
		path: 'patient/registration',
		element: <PatientRegistration />,
	},

	{
		path: 'patient/view',
		element: <ViewPatientPage {...admissionProps} />,
		children: [
			{
				index: true,
				element: <ViewPatientDefaultPage {...admissionProps} />,
			},
			{
				path: 'edit',
				element: <PatientEdit {...admissionProps} />,
			},
			{
				path: 'dashboard',
				element: <DashboardPage {...admissionProps} />,
			},
			{
				path: 'account-summary',
				element: <AccountSummaryPage {...admissionProps} />,
				children: accountSummaryRoutes,
			},
			// {
			// 	path: 'admit-patient',
			// 	element: <AdmitPatientPage {...admissionProps} />,
			// },
			// {
			// 	path: 'baby-status',
			// 	element: <BabyStatusPage {...admissionProps} />,
			// },
			// {
			// 	path: 'cancel-admission',
			// element: <CancelAdmissionPage {...admissionProps} />,
			// },
			{
				path: 'claim-form',
				element: <ClaimFormPage {...admissionProps} />,
			},
			{
				path: 'credit-note',
				element: <CreditNotePage {...admissionProps} />,
			},
			{
				path: 'diagnosis',
				element: <DiagnosisPage {...admissionProps} />,
			},
			{
				path: 'diet-history',
				element: <DietHistoryPage {...admissionProps} />,
			},
			{
				path: 'requisition-list',
				element: <RequisitionsPage {...admissionProps} />,
			},
			{
				path: 'requisition-view',
				element: <ViewRequisitionPage {...admissionProps} />,
			},
			{
				path: 'requisition-edit',
				element: <EditRequisitionPage {...admissionProps} />,
			},
			{
				path: 'diet-plan',
				element: <DietPlanPage {...admissionProps} />,
			},
			// {
			// 	path: 'discharge',
			// 	element: <DischargePage {...admissionProps} />,
			// },
			// {
			// 	path: 'disposition',
			// 	element: <DispositionPage {...admissionProps} />,
			// },
			{
				path: 'doctors-physicians',
				element: <DoctorsPhysiciansPage {...admissionProps} />,
			},
			{
				path: 'doctors-physicians-fees',
				element: <DoctorsPhysiciansFeesPage {...admissionProps} />,
			},
			{
				path: 'doctors-physicians-roles',
				element: <DoctorsPhysiciansRolesPage {...admissionProps} />,
			},
			{
				path: 'drug-resistance',
				element: <DrugResistancePage {...admissionProps} />,
			},
			// {
			// 	path: 'er-time-left',
			// 	element: <ERTimeLeftPage {...admissionProps} />,
			// },
			{
				path: 'examinations-procedures',
				element: <ExaminationsProceduresPage {...admissionProps} />,
			},
			{
				path: 'financial-assistance-guarantor',
				element: <FinancialAssistanceGuarantorPage {...admissionProps} />,
			},
			{
				path: 'for-billing',
				element: <ForBillingPage {...admissionProps} />,
			},
			{
				path: 'for-discharge',
				element: <ForDischargePage {...admissionProps} />,
			},
			{
				path: 'hmo-medical-packages',
				element: <HMOMedicalPackagesPage {...admissionProps} />,
			},
			{
				path: 'instruction',
				element: <InstructionPage {...admissionProps} />,
			},
			{
				path: 'maternity-form',
				element: <MaternityFormPage {...admissionProps} />,
			},
			{
				path: 'medical-packages',
				element: <MedicalPackagesPage {...admissionProps} />,
			},
			{
				path: 'new-requisition',
				element: <NewRequisitionPage {...admissionProps} />,
			},
			{
				path: 'patient-operations',
				element: <PatientOperationsPage {...admissionProps} />,
			},
			{
				path: 'post-charges',
				element: <PostChargesPage {...admissionProps} />,
			},
			{
				path: 'post-diet',
				element: <PostDietPage {...admissionProps} />,
			},
			{
				path: 'refer-to-other-clinic',
				element: <ReferToOtherClinicPage {...admissionProps} />,
			},
			// {
			// 	path: 'relocate',
			// 	element: <RelocatePage {...admissionProps} />,
			// },
			{
				path: 'reports',
				element: <ReportsPage {...admissionProps} />,
			},
			{
				path: 'requisition-list',
				element: <RequisitionListPage {...admissionProps} />,
			},
			// {
			// 	path: 'revert-patient-status',
			// 	element: <RevertPatientStatusPage {...admissionProps} />,
			// },
			{
				path: 'rooms',
				element: <RoomsPage {...admissionProps} />,
			},
			// {
			// 	path: 'service-type',
			// 	element: <ServiceTypePage {...admissionProps} />,
			// },
			// {
			// 	path: 'tag-untag-for-clearance',
			// 	element: <TagUntagForClearanceDialog {...admissionProps} />,
			// },
			{
				path: 'transfer-to-er',
				element: <TransferToERPage {...admissionProps} />,
			},
			{
				path: 'transfer-to-opd',
				element: <TransferToOPDPage {...admissionProps} />,
			},
			{
				path: 'vital-signs',
				element: <VitalSignsPage {...admissionProps} />,
			},
			{
				path: 'patient-adjustment',
				element: <PatientAdjustmentPage {...admissionProps} />,
			},
		],
	},
];

export default route;
