import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const PurchaseRequestApprovalLevel1Page = lazy(
	() => import('modules/mms/pages/PurchaseRequestApprovalLevel1/PurchaseRequestApprovalLevel1Page')
);

const PurchaseRequestApprovalPendingPage = lazy(
	() => import('modules/mms/pages/PurchaseRequestApprovalLevel1/pages/PurchaseRequestApprovalPending/PurchaseRequestApprovalPendingPage')
);

const PurchaseRequestApprovalApprovedPage = lazy(
	() => import('modules/mms/pages/PurchaseRequestApprovalLevel1/pages/PurchaseRequestApprovalApproved/PurchaseRequestApprovalApprovedPage')
);

const PurchaseRequestApprovalRejectedPage = lazy(
	() => import('modules/mms/pages/PurchaseRequestApprovalLevel1/pages/PurchaseRequestApprovalRejected/PurchaseRequestApprovalRejectedPage')
);

const route: RouteObject = {
	path: 'purchase-request-approval-level-1',
	element: <PurchaseRequestApprovalLevel1Page />,
	children: [
		{ index: true, element: <PurchaseRequestApprovalPendingPage /> },
		{ path: 'approved', element: <PurchaseRequestApprovalApprovedPage /> },
		{ path: 'rejected', element: <PurchaseRequestApprovalRejectedPage /> },
	],
};

export default route;
