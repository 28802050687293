import BaseDialog from 'components/feedback/BaseDialog';
import BaseFormDialog from 'components/feedback/BaseFormDialog';
import ConfirmationDialog from 'components/feedback/ConfirmationDialog';
import FormDatePicker from 'components/form/FormDatePicker';
import FormTextField from 'components/form/FormTextField';
import BaseDatePicker from 'components/inputs/BaseDatePicker';
import BaseTextField from 'components/inputs/BaseTextField';
import AddButton from 'components/inputs/buttons/AddButton';
import SaveButton from 'components/inputs/buttons/SaveButton';
import useApi from 'hooks/useApi';
import localforage from 'localforage';
import { TruckDeliveryOutline } from 'mdi-material-ui';
import { useEffect, useState } from 'react';
import authStore from 'stores/auth-store';
import Swal from 'sweetalert2';
import ItemCategoryModel from 'types/item-category.model';
import ItemModel from 'types/item.model';
import SubDepartmentModel from 'types/sub-department.model';
import StockCardMain from '../../types/StockCardMain';
import UnitDosingSearchItemDialog from './UnitDosingSearchItemDialog';

interface UnitDosingAddItemDialogProps {
	open: boolean;
	onClose: () => void;
	item: StockCardMain;
	main_item_category: ItemCategoryModel;
	refresh: () => void;
}

const UnitDosingAddItemDialog = ({ open, onClose, item, main_item_category, refresh }: UnitDosingAddItemDialogProps) => {
	const [selectedItem, setSelectedItem] = useState<ItemModel>({} as ItemModel);
	const [mainItemQty, setMainItemQty] = useState<number>(0);
	const [unitConversionQty, setUnitConversionQty] = useState<number>(0);
	const [itemExpiry, setItemExpiry] = useState<Date>(new Date());
	const [convertedUnitCost, setConvertedUnitCost] = useState<number>(0);
	const [convertedQty, setConvertedQty] = useState<number>(0);
	const [newLotNo, setNewLotNo] = useState<string>('');
	const [saveData, setSaveData] = useState<{}>();
	const [sd, setSD] = useState<SubDepartmentModel>({} as SubDepartmentModel);

	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [openConfDialog, setOpenConfDialog] = useState<boolean>(false);

	const {
		callApi: getLastestIDApi,
		data,
		loading,
	} = useApi<{ ID: number }>({
		url: 'mms/items/inventory/unit-dosing/latest-id',
		onSuccess({ data }) {
			setNewLotNo('UD' + ((data?.response?.ID ?? 0) + 1) + item.LotNo);
		},
		immediate: false,
	});

	const { callApi: insertUnitDosingApi } = useApi<{}>({
		url: 'mms/items/inventory/unit-dosing-insert',
		method: 'post',
		onSuccess() {
			refresh();
			onClose();
		},
		immediate: false,
	});

	const calculateDosing = (mainItemQty: number, mainItemConversionQty: number) => {
		let convertedUnitCost = 0;
		let convertedQty = 0;

		if (mainItemQty * mainItemConversionQty > 0) {
			convertedUnitCost = item.BasedPrice / (mainItemQty * mainItemConversionQty);
			convertedQty = mainItemQty * mainItemConversionQty;
		}

		setConvertedUnitCost(convertedUnitCost);
		setConvertedQty(convertedQty);
	};

	const save = () => {
		if (selectedItem.ItemID != undefined && convertedUnitCost > 0) {
			let unitDosing = {
				MainSILineID: item.SILineID,
				MainItemID: item.ItemID,
				MainItemQty: mainItemQty,
				unitConversionQty: unitConversionQty,
				DosingItemID: selectedItem.ItemID,
				DosingItemUnit: selectedItem.BasedUnit,
				DosingItemQty: convertedQty,
				DosingUnitPrice: convertedUnitCost,
				DosingExpirationDate: itemExpiry,
				isActive: true,
			};

			let si = {
				TargetSubDeptID: sd.SubDeptID,
				SourceSubDeptID: sd.SubDeptID,
				IssuanceRemarks: 'unit-dosing',
				isTransClose: false,
				isPost: true,
				isActive: true,
			};

			let siLine = {
				LotNo: newLotNo,
				Barcode: newLotNo,
				ItemExpiry: itemExpiry,
				ItemID: selectedItem.ItemID,
				Unit: selectedItem.BasedUnit,
				IssueQty: convertedQty,
				UnitQty: convertedQty,
				SmallUnitPrice: convertedUnitCost,
				TransactionType: 'UD',
				isInventory: true,
				isPost: true,
				isActive: true,
				SILineIDMain: item.SILineID,
				FundSourceID: 1,
			};

			setSaveData({
				unitDosing: unitDosing,
				si: si,
				siLine: siLine,
				main_item_category: main_item_category,
				dosing_item_category: selectedItem.item_category,
				EncodedBy: authStore?.user?.CompleteName ?? 'Unit Dosing',
			});

			setOpenConfDialog(true);
		} else
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Input all required fields',
				showConfirmButton: false,
				timer: 2500,
			});
	};

	const close = () => {
		setSelectedItem({} as ItemModel);
		setMainItemQty(0);
		setUnitConversionQty(0);
		setConvertedQty(0);
		setConvertedUnitCost(0);

		onClose();
	};

	useEffect(() => {
		localforage.iterate(function (val, key, iteration) {
			if (key == 'subDept') setSD(val as SubDepartmentModel);
		});
	}, []);

	useEffect(() => {
		if (open == true) getLastestIDApi();
	}, [open]);

	return (
		<>
			<ConfirmationDialog open={openConfDialog} onClose={() => setOpenConfDialog(false)} onAgree={() => insertUnitDosingApi(saveData)} />
			<UnitDosingSearchItemDialog open={openDialog} onClose={() => setOpenDialog(false)} setSelectedItem={setSelectedItem} />
			<BaseDialog title="Add Unit Dosing" open={open} onClose={close} maxWidth="md">
				<div className="flex flex-grow flex-col gap-3">
					<div className="flex flex-nowrap gap-3">
						<BaseTextField label="Lot Number" value={item.LotNo} readOnly />
						<BaseTextField label="Barcode" value={item.Barcode} readOnly />
						<BaseTextField label="Expiration" value={item.ItemExpiry} readOnly />
					</div>

					<BaseTextField label="Item Name" value={item.Description} readOnly />

					<div className="flex no-wrap gap-3">
						<BaseTextField label="Unit" value={item.BasedUnit} readOnly />
						<BaseTextField label="Quantity" value={item.RemainingQty} readOnly />
						<BaseTextField label="Unit Cost" value={item.BasedPrice} readOnly />
					</div>

					<div className="flex flex-col   place-self-center mt-5">
						<div className="font-bold">Unit Dosing Conversion Information</div>
						<div className="flex place-self-center">Per Quantity of</div>
					</div>

					<div className="flex no-wrap gap-3">
						<BaseTextField
							label="Quantity to Convert"
							value={mainItemQty}
							onChange={(e) => {
								setMainItemQty(Number(e.target.value));
								calculateDosing(Number(e.target.value), unitConversionQty);
							}}
						/>
						<BaseTextField
							label={item.Description}
							value={unitConversionQty}
							onChange={(e) => {
								setUnitConversionQty(Number(e.target.value));
								calculateDosing(mainItemQty, Number(e.target.value));
							}}
						/>
						<BaseTextField label="Converted Unit Cost" value={convertedUnitCost} readOnly />
					</div>

					<div className="flex flex-col font-bold place-self-center mt-5">Unit Dosing Item Details</div>

					<div className="flex place-self-end">
						<AddButton onClick={() => setOpenDialog(true)}>Select Item</AddButton>
					</div>

					<BaseTextField label="Unit Dosing Item for" value={selectedItem.Description ?? ''} />

					<div className="flex no-wrap gap-3 mt-8">
						<BaseTextField label="Converted Total Quantity" value={convertedQty} readOnly />
						<BaseTextField label="Base Price" value={selectedItem.BasedPrice ?? 0} readOnly />
						<BaseTextField label="Converted Unit Cost" value={convertedUnitCost} readOnly />
					</div>

					<div className="flex no-wrap gap-3">
						<BaseTextField label="Lot Number / Barcode" value={newLotNo} readOnly />
						<BaseDatePicker label="Expiry" value={itemExpiry} onChange={(e) => setItemExpiry(e)} />
					</div>

					<div className="flex place-self-end">
						<SaveButton onClick={save} />
					</div>
				</div>
			</BaseDialog>
		</>
	);
};

export default UnitDosingAddItemDialog;
