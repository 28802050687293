import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import BaseButton, { BaseButtonProps } from './BaseButton';

const SaveButton: React.FC<BaseButtonProps> = ({ children, ...props }) => {
	return (
		<BaseButton type="submit" minWidth={120} {...props}>
			<SaveIcon className="mr-1" />
			{children ?? 'Save'}
		</BaseButton>
	);
};

export default SaveButton;
