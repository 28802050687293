import pathAccountingSettingsModel from 'modules/ams/pages/AccountingSettings/types/pathAccountingSettings';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const AccountingSettingsPage = lazy(() => import('modules/ams/pages/AccountingSettings/AccountingSettings'));
const AccountingSettingsMainPage = lazy(() => import('modules/ams/pages/AccountingSettings/pages/AccountingSettingsMainPage'));
const  pathAccoutingSettings: pathAccountingSettingsModel = {
	module: 'Accounting-Settings',
	path: 'accounting-settings',
};

const route: RouteObject[] = [
	{
		element: <AccountingSettingsPage {...pathAccoutingSettings} />,
		children: [
			{
				index: true,
				element: <AccountingSettingsMainPage {...pathAccoutingSettings} />,
			},
		],
	},
];

export default route;
