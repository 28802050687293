import OperatingRoomModel from 'modules/his/pages/OperatingRoom/types/operating-room.model';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const OperatingRoomPage = lazy(() => import('modules/his/pages/OperatingRoom/OperatingRoomPage'));
const OperatingRoomMainPage = lazy(() => import('modules/his/pages/OperatingRoom/pages/OperatingRoomMainPage/OperatingRoomMainPage'));

const operatingRoomProps: OperatingRoomModel = {
	module: 'Operating Room',
	path: 'operating-room',
};

const route: RouteObject[] = [
	{
		element: <OperatingRoomPage {...operatingRoomProps} />,
		children: [
			{
				index: true,
				element: <OperatingRoomMainPage {...operatingRoomProps} />,
			},
		],
	},
];

export default route;
