import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const PurchaseRequestPage = lazy(() => import('modules/mms/pages/PurchaseRequest/PurchaseRequestPage'));
const PendingRequestsPage = lazy(() => import('modules/mms/pages/PurchaseRequest/pages/PendingRequests/PendingRequestsPage'));
const PostedRequestsPage = lazy(() => import('modules/mms/pages/PurchaseRequest/pages/PostedRequests/PostedRequestsPage'));
const VoidedRequestsPage = lazy(() => import('modules/mms/pages/PurchaseRequest/pages/VoidedRequests/VoidedRequestsPage'));

const route: RouteObject = {
	path: 'purchase-request',
	element: <PurchaseRequestPage />,
	children: [
		{ index: true, element: <PendingRequestsPage /> },
		{ path: 'posted-requests', element: <PostedRequestsPage /> },
		{ path: 'voided-requests', element: <VoidedRequestsPage /> },
	],
};

export default route;
