import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import moduleJournalsRoutes from './ams-module-journals-routes';
import manualBillsRoutes from './ams-manual-bills-routes';
import accountingTemplatesRoutes from './ams-accounting-templates-routes';
import chartOfAccountsRoutes from './ams-chart-of-accounts-routes';
import reportsRoutes from './ams-reports-routes';
import accountingSettingsRoutes from './ams-accounting-settings-routes';
import bankSeriesRoutes from './ams-bank-series-routes';
import pettyCashCustodianListRoutes from './ams-petty-cash-custodian-list-routes';

const AMSLayout = lazy(() => import('modules/ams/layout/AMSLayout'));
const ManagementDashboardPage = lazy(() => import('modules/ams/pages/ManagementDashboard/ManagementDashboardPage'));
const VouchersPage = lazy(() => import('modules/ams/pages/Vouchers/VouchersPage'));
const PettyCashCustodianListPage = lazy(() => import('modules/ams/pages/PettyCashCustodianList/pages/PettyCashCustodianListMainPage'));

const route: RouteObject = {
	path: '/ams',
	element: <AMSLayout />,
	children: [
		{ index: true, element: <ManagementDashboardPage /> },
		{ path: 'accounting-templates', children: accountingTemplatesRoutes },
		{ path: 'accounting-settings', children: accountingSettingsRoutes },
		{ path: 'bank-series', children: bankSeriesRoutes },
		{ path: 'journals', children: moduleJournalsRoutes },
		{ path: 'manual-bills', children: manualBillsRoutes },
		{ path: 'vouchers', element: <VouchersPage /> },
		{ path: 'chart-of-accounts', children: chartOfAccountsRoutes },
		{ path: 'reports', children: reportsRoutes },
		{ path: 'petty-cash-custodian-list', children: pettyCashCustodianListRoutes },
	],
};

export default route;
