import BaseTable from 'components/data-display/BaseTable';
import BaseDialog from 'components/feedback/BaseDialog';
import SendIcon from '@mui/icons-material/Send';
import BaseButton from 'components/inputs/buttons/BaseButton';
import AccountTemplateModel from 'types/account-template.model';
import useApi from 'hooks/useApi';
import { useState } from 'react';
import SearchTextField from 'components/inputs/text-fields/SearchTextField';
import ConfirmationDialog from 'components/feedback/ConfirmationDialog';

interface AccountingTemplateDialogProps {
	open: boolean;
	onClose: () => void;
	setTemplate: any;
}

const AccountingTemplateDialog = ({ open, onClose, setTemplate }: AccountingTemplateDialogProps) => {
	const [searchWord, setSearchWord] = useState('');
	const [openConfDialog, setOpenConfDialog] = useState(false);

	const { data: accTemplates, loading: dashboardLoading } = useApi<AccountTemplateModel[]>({
		url: 'ams/account-templates/for-transaction',
		params: {
			isHISNonTrade: false,
			isHISPostAdjustment: true,
			isAMSManualJournal: false,
			isAMSManualVoucher: false,
			isAMSPettyCash: false,
			isHRISCharges: false,
		},
	});

	const filteredTemplates = searchWord
		? accTemplates?.reduce((acc: AccountTemplateModel[], item) => {
				if (
					item?.Description.toLowerCase().includes(searchWord.toLowerCase()) ||
					item?.Name.toLowerCase().includes(searchWord.toLowerCase())
				) {
					acc.push(item);
				}
				return acc;
		  }, [])
		: accTemplates;

	const selectTemplate = (data: AccountTemplateModel) => {
		setTemplate(data);
		setOpenConfDialog(true);
	};

	return (
		<BaseDialog title="Accounting Templates" open={open} onClose={onClose}>
			<ConfirmationDialog open={openConfDialog} onAgree={() => onClose()} onClose={() => setOpenConfDialog(false)}>
				Are you sure you want to select this template?
			</ConfirmationDialog>
			<SearchTextField value={searchWord} onChange={(e) => setSearchWord(e.target.value)} />
			<BaseTable
				className="mt-7"
				items={filteredTemplates}
				loading={dashboardLoading}
				headers={[
					{ label: 'Template Name', value: 'Name' },
					{ label: 'Description', value: 'Description' },
				]}
				actionColumn={(data) => (
					<div className="flex">
						<BaseButton icon onClick={() => selectTemplate(data)}>
							<SendIcon />
						</BaseButton>
					</div>
				)}
			/>
		</BaseDialog>
	);
};

export default AccountingTemplateDialog;
