import BaseDialog from 'components/feedback/BaseDialog';
import ConfirmationDialog from 'components/feedback/ConfirmationDialog';
import BaseDatePicker from 'components/inputs/BaseDatePicker';
import BaseTextField from 'components/inputs/BaseTextField';
import SaveButton from 'components/inputs/buttons/SaveButton';
import useApi from 'hooks/useApi';
import { useState } from 'react';
import authStore from 'stores/auth-store';
import StockCardMain from '../../types/StockCardMain';

interface EditItemExpirtDialogProps {
	open: boolean;
	onClose: () => void;
	selectedItem: StockCardMain;
	refresh: () => void;
}

const EditItemExpiryDialog = ({ open, onClose, selectedItem, refresh }: EditItemExpirtDialogProps) => {
	const [date, setDate] = useState<Date>(new Date(new Date().setFullYear(new Date().getFullYear() + 5)));
	const [saveData, setSaveData] = useState<{}>();
	const [openConf, setOpenConf] = useState<boolean>(false);

	const { callApi: editItemExpiryApi } = useApi<{}>({
		url: 'mms/items/inventory/item-expiry/update',
		method: 'patch',
		onSuccess() {
			refresh();
			onClose();
		},
		immediate: false,
	});

	const save = () => {
		setSaveData({
			SILineID: selectedItem.SILineID,
			ItemExpiry: date,
			EncodedBy: authStore?.user?.CompleteName ?? 'Edit Item Expiry',
		});

		setOpenConf(true);
	};

	return (
		<>
			<ConfirmationDialog open={openConf} onClose={() => setOpenConf(false)} onAgree={() => editItemExpiryApi(saveData)} />
			<BaseDialog title="Edit Item Expiry" open={open} onClose={onClose} maxWidth="sm">
				<div className="flex flex-grow flex-col gap-3">
					<BaseTextField label="Stock No" value={selectedItem.SILineID} readOnly />
					<BaseTextField label="Lot Number" value={selectedItem.LotNo} readOnly />
					<BaseDatePicker label="Item Expiry" value={date} onChange={(e) => setDate(e)} />
					<div className="flex place-self-end">
						<SaveButton onClick={save} />
					</div>
				</div>
			</BaseDialog>
		</>
	);
};

export default EditItemExpiryDialog;
