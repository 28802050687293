import React, { Suspense } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';

import { HLoadingIndicator } from '@hybrain/mui.ui.feedback.h-loading-indicator';

import hisRoutes from './his/his-routes';
import mmsRoutes from './mms/mms-routes';
import amsRoutes from './ams/ams-routes';
import hrisRoutes from './hris/hris-routes';
import pcsRouets from './pcs/pcs-routes';
import publicRoutes from './public-routes';

const RoutesList = () => {
	const routes = useRoutes([hisRoutes, publicRoutes, mmsRoutes, amsRoutes, hrisRoutes, pcsRouets]);

	return routes;
};

const RoutesIndex = () => {
	return (
		<BrowserRouter>
			<Suspense
				fallback={
					<div className="flex flex-col min-h-screen">
						<HLoadingIndicator />
					</div>
				}
			>
				<RoutesList />
			</Suspense>
		</BrowserRouter>
	);
};

export default RoutesIndex;
