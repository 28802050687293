import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const ItemDisposalPage = lazy(() => import('modules/mms/pages/ItemDisposal/ItemDisposalPage'));
const ItemDisposalPendingPage = lazy(() => import('modules/mms/pages/ItemDisposal/pages/ItemDisposalPending/ItemDisposalPendingPage'));
const ItemDisposalApprovedPage = lazy(() => import('modules/mms/pages/ItemDisposal/pages/ItemDisposalApproved/ItemDisposalApprovedPage'));
const ItemDisposalReleasePage = lazy(() => import('modules/mms/pages/ItemDisposal/pages/ItemDisposalRelease/ItemDisposalReleasePage'));
const ItemDisposalVoidedPage = lazy(() => import('modules/mms/pages/ItemDisposal/pages/ItemDisposalVoided/ItemDisposalVoidedPage'));
const ItemDisposalWithPaymentPage = lazy(
	() => import('modules/mms/pages/ItemDisposal/pages/ItemDisposalWithPayment/ItemDisposalWithPaymentPage')
);

const route: RouteObject = {
	path: 'item-disposal',
	element: <ItemDisposalPage />,
	children: [
		{ index: true, element: <ItemDisposalPendingPage /> },
		{ path: 'approved', element: <ItemDisposalApprovedPage /> },
		{ path: 'release', element: <ItemDisposalReleasePage /> },
		{ path: 'voided', element: <ItemDisposalVoidedPage /> },
		{ path: 'with-payment', element: <ItemDisposalWithPaymentPage /> },
	],
};

export default route;
