import HumanResourceModel from 'modules/hris/pages/HumanResource/types/human-resource.model';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const HumanResourcePage = lazy(() => import('modules/hris/pages/HumanResource/HumanResourceMainPage'));
const HumanResourceEmployeePage = lazy(() => import('modules/hris/pages/HumanResource/pages/HumanResourceEmployeePage'));
const HumanResourceAnnounceMentPage = lazy(() => import('modules/hris/pages/HumanResource/pages/HumanResourceAnnounceMentPage'));
const HumanResourceEventsPage = lazy(() => import('modules/hris/pages/HumanResource/pages/HumanResourceEventsPage'));

const HumanResourceProps: HumanResourceModel = {
	module: 'Human Resource',
	path: 'human-resource',
};
const route: RouteObject[] = [
	{
		element: <HumanResourcePage {...HumanResourceProps}/>,
		children: [
			{
				index: true,
				element: <HumanResourceEmployeePage {...HumanResourceProps}/>,
			},
            {
				path: 'announcement',
				element: <HumanResourceAnnounceMentPage {...HumanResourceProps}/>,
			},
			{
				path: 'events',
				element: <HumanResourceEventsPage {...HumanResourceProps}/>,
			},
		],
	},
];
export default route;
